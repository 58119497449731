import { useRef, useState } from "react";
import { QueryBuilderComponent } from "@syncfusion/ej2-react-querybuilder";
import { LegTemplate } from "journal-lib";
import { Autocomplete, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { selectorLegRuleFields, selectorTotalRuleFields } from "redux/SmartOptionsAI";
import { useSelector } from "react-redux";
import { useLegSettingStyles } from "./ActivityTemplateLegSetting.styles";
import _ from "lodash";

interface ActivityTemplateLegSettingProps {
    isTotal: boolean;
    legTemplate: LegTemplate;
    onLegTemplateChange: (fieldName: string, newData: any) => void;
}

export const ActivityTemplateLegSetting = ({ isTotal, legTemplate, onLegTemplateChange }: ActivityTemplateLegSettingProps) => {
    const classes = useLegSettingStyles(); // Use the styles

    const qbObj = useRef<QueryBuilderComponent>(null);
    // const dialogInstance = useRef<DialogComponent>(null);
    const legRuleFields = useSelector(selectorLegRuleFields);
    const clonedLegRuleFields = _.cloneDeep(legRuleFields);
    const totalRuleFields = useSelector(selectorTotalRuleFields);
    const clonedTotalRuleFields = _.cloneDeep(totalRuleFields);

    const [localQty, setLocalQty] = useState<number | null>(Number(legTemplate?.qty)); // Store independent state per tab
    const [localPutCall, setLocalPutCall] = useState<string>(legTemplate?.type);
    const [localSide, setLocalSide] = useState<string>(legTemplate?.side);
    const [localRules, setLocalRules] = useState<any>(_.cloneDeep(legTemplate?.legRule) || {});

    const handleRuleChange = () => {
        if (qbObj.current) {
            const validRule = _.cloneDeep(qbObj.current.getValidRules(qbObj.current.rule)); // Clone before setting
            onLegTemplateChange("legRule", validRule);
        }
    };

    const sideOptions = [
        { label: "STO", value: "sto" },
        { label: "STC", value: "stc" },
        { label: "BTO", value: "bto" },
        { label: "BTC", value: "btc" }
    ];

    const typeOptions = [
        { label: "Put", value: "put" },
        { label: "Call", value: "call" }
    ];

    return (
        <div>
            {isTotal ? null : (<Grid container spacing={3} style={{ padding: "0 20px" }}>
                <Grid size={3} sx={{ mt: 2 }}> {/* Added margin top */}
                    <TextField
                        fullWidth
                        id="qty"
                        label="QTY"
                        type="text"
                        value={localQty !== null && !isNaN(localQty) ? localQty : ""}
                        onChange={(e) => {
                            const newValue = e.target.value ? Number(e.target.value) : null;
                            setLocalQty(newValue); // Update local state
                        }}
                        onBlur={() => {
                            onLegTemplateChange("qty", localQty);
                        }}
                    />
                </Grid>
                <Grid size={3} sx={{ mt: 2 }}> {/* Added margin top */}
                    <Autocomplete
                        value={typeOptions.find(option => option.value === localPutCall) || null}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.value) {
                                setLocalPutCall(newValue.value); // Update local state
                                onLegTemplateChange("type", newValue.value);
                            } else {
                                setLocalPutCall(""); // Update local state
                                onLegTemplateChange("type", "");
                            }
                        }}
                        autoSelect
                        id="type"
                        options={typeOptions}
                        getOptionLabel={(option: any) =>
                            option?.label ? option.label : ""
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Put/Call"
                            />
                        )}
                    />
                </Grid>
                <Grid size={3} sx={{ mt: 2 }}> {/* Added margin top */}
                    <Autocomplete
                        value={sideOptions.find(option => option.value === localSide) || null}
                        onChange={(event, newValue) => {
                            if (newValue && newValue.value) {
                                setLocalSide(newValue.value); // Update local state
                                onLegTemplateChange("side", newValue.value);
                            } else {
                                setLocalSide(""); // Update local state
                                onLegTemplateChange("side", "");
                            }
                        }}
                        autoSelect
                        id="side"
                        options={sideOptions}
                        getOptionLabel={(option: any) =>
                            option?.label ? option.label : ""
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Side"
                            />
                        )}
                    />
                </Grid>
            </Grid>)
            }
            <div className={classes.queryBuilder}>
                <QueryBuilderComponent
                    ref={qbObj}
                    dataSource={(isTotal ? clonedTotalRuleFields : clonedLegRuleFields) ?? []}
                    columns={(isTotal ? clonedTotalRuleFields : clonedLegRuleFields) ?? []}
                    rule={localRules}
                    ruleChange={handleRuleChange}
                    immediateModeDelay = {1000}
                />
            </div>
        </div>
    );
};
