import { createAction } from "@reduxjs/toolkit";
import { AccountStoreState, GetAccountResponse } from "./Account.types";

export const resetAccount = createAction("account/reset");
export const getAccount = createAction("journalEntry/getAccount");

export const getAccountResolved = createAction<AccountStoreState>(
  "account/getAccountResolved"
);

export const getAccountRejected = createAction(
  "account/getAccountRejected"
);

export const getAccountBalance = createAction<{ id: string }>("account/getAccountBalance");

export const getAccountBalanceResolved = createAction<AccountStoreState>(
  "account/getAccountBalanceResolved"
);

export const getAccountBalanceRejected = createAction(
  "account/getAccountBalanceRejected"
);

export const addAccount = createAction<{ description: string }>(
  "account/addAccount"
);

export const addAccountResolved = createAction<GetAccountResponse>(
  "account/addAccountResolved"
);

export const addAccountRejected = createAction(
  "account/addAccountRejected"
);

export const deleteAccount = createAction<string>("account/deleteAccount");

export const deleteAccountResolved = createAction<string>(
  "account/deleteAccountResolved"
);

export const deleteAccountRejected = createAction(
  "account/deleteAccountRejected"
);

export const editAccount = createAction<GetAccountResponse>(
  "account/editAccount"
);

export const editAccountResolved = createAction<GetAccountResponse>(
  "account/editAccountResolved"
);

export const editAccountRejected = createAction(
  "account/editAccountRejected"
);
