import { Activity, JournalEntry, StrategyEnum, User } from "journal-lib";
import moment from "moment";
import { currency2DigitFormatter } from "utils";
import { discordWebhook } from "redux/Feed";

export const getDiscord = (
  activity: Activity,
  entry: JournalEntry,
  user: User
): discordWebhook => {
  return {
    username: user.name,
    content: activity.note,
    embeds: [
      {
        url: "http://trader-journal.s3-website.us-east-2.amazonaws.com/newsfeed",
        // author: {
        //   name: user.name,
        //   url:
        //     "http://trader-journal.s3-website.us-east-2.amazonaws.com:3000/newsfeed",
        // },
        title: `$${entry.symbol} ${moment(activity.date).format("M/D")} ${
          activity.type === "closing"
            ? "Closing - "
            : activity.type === "roll"
            ? "Roll - "
            : activity.type === "add"
            ? "Add - "
            : "Open - "
        } ${
          entry.position?.legs?.length > 0 &&
          entry.position.legs[0].option?.expDate
            ? `${moment(activity.legs[0].leg?.option.expDate).format("M/D")} `
            : ""
        }${activity.desc}`,
        fields: [
          {
            name: "​",
            value:
              activity.legs
                .map(
                  (leg) =>
                    `${leg.type.toUpperCase()} ${
                      leg.leg?.option.expDate
                        ? moment(leg.leg?.option.expDate).format("M/D/YY")
                        : ""
                    } ${
                      leg.leg?.option.strike
                        ? ` __${leg.leg?.option.strike}__ `
                        : " "
                    }${
                      leg.leg?.option.type === "call"
                        ? "Call"
                        : leg.leg?.option.type === "put"
                        ? "Put"
                        : leg.leg?.option.type === "stock"
                        ? "Stock"
                        : leg.leg?.option.type === "future"
                        ? "Future"
                        : ""
                    } x ${leg.leg?.qty}`
                )
                .join("\n") +
              `\n${
                (activity.price ?? 0) > 0 ? "Credits" : "Debits"
              }: ${currency2DigitFormatter.format(
                activity.desc === "Long Stock" ||
                  activity.desc === "Short Stock"
                  ? Math.abs(+(activity.price ?? 0) * 100)
                  : Math.abs(+(activity.price ?? 0))
              )}${activity.note == "" ? "" : `\nNotes: ${activity.note}`}`,
          },
          {
            name: "​",
            value: "​",
          },
          {
            name: "Current Position",
            value:
              entry.position.legs
                .map(
                  (leg) =>
                    `${
                      leg.option.expDate
                        ? moment(leg.option.expDate).format("M/D/YY")
                        : ""
                    } ${
                      leg.option.strike ? ` __${leg.option.strike}__ ` : " "
                    }${
                      leg.option.type === "call"
                        ? "Call"
                        : leg.option.type === "put"
                        ? "Put"
                        : leg.option.type === "stock"
                        ? "Stock"
                        : leg.option.type === "future"
                        ? "Future"
                        : ""
                    } x ${leg.qty}`
                )
                .join("\n") +
              `\nTotal ${
                entry.position.cumPrice > 0 ? "Credits" : "Debits"
              }: ${currency2DigitFormatter.format(
                (entry?.position
                  ?.name as unknown as keyof typeof StrategyEnum) ===
                  "LongStock" ||
                  (entry?.position
                    ?.name as unknown as keyof typeof StrategyEnum) ===
                    "ShortStock"
                  ? Math.abs(+entry?.position?.cumPrice * 100)
                  : Math.abs(+entry?.position?.cumPrice)
              )}`,
          },
        ],
        color: "14177041",
        // timestamp: moment().toISOString(),
      },
    ],
  };
};
