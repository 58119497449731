import { API } from "aws-amplify";
import { put, select, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "@reduxjs/toolkit";
import { ActivityTemplate, AdvancedActivity } from "journal-lib";
import { onError } from "libs/errorLib";
import { GetActivitiesResponse, GetActivityTemplatesResponse, GetRuleFieldsResponse } from "./SmartOptionsAI.types";
import { addActivityTemplate, addActivityTemplateRejected, addActivityTemplateResolved, deleteActivityTemplate, deleteActivityTemplateRejected, deleteActivityTemplateResolved, editActivityTemplate, editActivityTemplateRejected, editActivityTemplateResolved, getActivityTemplates, getActivityTemplatesResolved, getLegRuleFieldsResolved, getTotalRuleFieldsResolved, getLegRuleFields, getTotalRuleFields, duplicateActivityTemplateResolved, duplicateActivityTemplateRejected, duplicateActivityTemplate, getActivitiesResolved, getActivities, templateToActivityResolved, templateToActivityRejected, templateToActivity, cancelOrderResolved, cancelOrder } from "./SmartOptionsAI.actions";
import { toggleAddActivityTemplateDrawer } from "redux/UI";
import { selectorAccountFilter, selectorEndDateFilter, selectorStartDateFilter, selectorStatusFilter, selectorSymbolFilter } from "./SmartOptionsAI.selectors";

function* getActivityTemplatesSaga() {
    //   const page: number = yield select(selectorPage);
    //   const pageSize: number = yield select(selectorPageSize);

    let address = `/smartAI/activityTemplates?`;
    address = address.substr(0, address.length - 1);

    const response: GetActivityTemplatesResponse = yield API.get(
        "optionOrder",
        address,
        {}
    );
    yield put(getActivityTemplatesResolved(response));
}

function* getActivitiesSaga() {
    //   const page: number = yield select(selectorPage);
    //   const pageSize: number = yield select(selectorPageSize);
    const filterByStartDate: string = yield select(selectorStartDateFilter);
    const filterByEndDate: string = yield select(selectorEndDateFilter);
    const filterBySymbol: string = yield select(selectorSymbolFilter);
    const filterByAccount: string = yield select(selectorAccountFilter);
    const filterByStatus: string = yield select(selectorStatusFilter);

    console.log("111");

    let params = new URLSearchParams();

    if (filterByStartDate) {
        params.append("startDate", filterByStartDate);
    }
    if (filterByEndDate) {
        params.append("endDate", filterByEndDate);
    }
    if (filterBySymbol) {
        params.append("symbol", filterBySymbol);
    }
    if (filterByAccount) {
        params.append("accountId", filterByAccount);
    }
    if (filterByStatus) {
        params.append("status", filterByStatus);
    }

    const address = params.toString()
        ? `/smartAI/activities?${params.toString()}`
        : `/smartAI/activities`;
    // let address = `/smartAI/activities?`;
    // address = address.substr(0, address.length - 1);

    const response: GetActivitiesResponse = yield API.get(
        "optionOrder",
        address,
        {}
    );
    yield put(getActivitiesResolved(response));
}

function* getLegRuleFieldsSaga() {
    //   const page: number = yield select(selectorPage);
    //   const pageSize: number = yield select(selectorPageSize);

    let address = `/smartAI/legRuleFields?`;
    address = address.substr(0, address.length - 1);

    const response: GetRuleFieldsResponse = yield API.get(
        "optionOrder",
        address,
        {}
    );
    yield put(getLegRuleFieldsResolved(response));
}

function* getTotalRuleFieldsSaga() {
    //   const page: number = yield select(selectorPage);
    //   const pageSize: number = yield select(selectorPageSize);

    let address = `/smartAI/totalRuleFields?`;
    address = address.substr(0, address.length - 1);

    const response: GetRuleFieldsResponse = yield API.get(
        "optionOrder",
        address,
        {}
    );
    yield put(getTotalRuleFieldsResolved(response));
}

function* addActivityTemplateSaga(action: AnyAction) {
    try {
        const newTemplate: ActivityTemplate = yield API.post(
            "optionOrder",
            "/smartAI/activityTemplate",
            {
                body: action.payload,
            }
        );
        yield put(addActivityTemplateResolved(newTemplate));
        yield put(toggleAddActivityTemplateDrawer());
        console.log('111');
        yield put(getActivityTemplates({}));
    } catch (e) {
        onError(e);
        yield put(addActivityTemplateRejected());
    }
}

function* deleteActivityTemplateSaga(action: AnyAction) {
    try {
        yield API.del("optionOrder", `/smartAI/activityTemplate/${action.payload.id}`, {});

        yield put(deleteActivityTemplateResolved(action.payload));
    } catch (e) {
        onError(e);
        yield put(deleteActivityTemplateRejected());
    }
}

function* editActivityTemplateSaga(action: AnyAction) {
    try {
        const updatedTemplate: ActivityTemplate = yield API.put(
            "optionOrder",
            "/smartAI/activityTemplate",
            {
                body: action.payload,
            }
        );
        yield put(editActivityTemplateResolved(updatedTemplate));
        yield put(toggleAddActivityTemplateDrawer());
    } catch (e) {
        onError(e);
        yield put(editActivityTemplateRejected());
    }
}

function* duplicateActivityTemplateSaga(action: AnyAction) {
    try {
        const newTemplate: ActivityTemplate = yield API.put(
            "optionOrder",
            `/smartAI/duplicateActivityTemplate/${action.payload.id}`,
            {}
        );
        yield put(duplicateActivityTemplateResolved(newTemplate));
        yield put(getActivityTemplates({}));
    } catch (e) {
        onError(e);
        yield put(duplicateActivityTemplateRejected());
    }
}

function* templateToActivitySaga(action: AnyAction) {
    try {
        const activity: AdvancedActivity = yield API.post(
            "optionOrder",
            "/smartAI/templateToActivity",
            {
                body: action.payload,
            }
        );
        yield put(templateToActivityResolved(activity));
        yield put(getActivities({}));
    } catch (e) {
        onError(e);
        yield put(templateToActivityRejected());
    }
}

function* cancelOrderSaga(action: AnyAction) {
    try {
        yield API.del(
            "optionOrder",
            `/smartAI/cancelOrder/${action.payload.id}`,
            {}
        );
        yield put(cancelOrderResolved());
        yield put(getActivities({}));
    } catch (e) {
        onError(e);
    }
}


function* watchActivityTemplates() {
    yield takeLatest(getActivityTemplates, getActivityTemplatesSaga);
}
function* watchGetActivities() {
    yield takeLatest(getActivities, getActivitiesSaga);
}
function* watchGetLegRuleFields() {
    yield takeLatest(getLegRuleFields, getLegRuleFieldsSaga);
}
function* watchGetTotalRuleFields() {
    yield takeLatest(getTotalRuleFields, getTotalRuleFieldsSaga);
}
function* watchGetActivityTemplate() {
    yield takeLatest(addActivityTemplate, addActivityTemplateSaga);
}
function* watchDeleteActivityTemplate() {
    yield takeLatest(deleteActivityTemplate, deleteActivityTemplateSaga);
}
function* watchEditActivityTemplate() {
    yield takeLatest(editActivityTemplate, editActivityTemplateSaga);
}
function* watchDuplicateActivityTemplate() {
    yield takeLatest(duplicateActivityTemplate, duplicateActivityTemplateSaga);
}
function* watchTemplateToActivity() {
    yield takeLatest(templateToActivity, templateToActivitySaga);
}
function* watchCancelOrder() {
    yield takeLatest(cancelOrder, cancelOrderSaga);
}

const sagaArray = [
    watchActivityTemplates(),
    watchGetActivities(),
    watchGetLegRuleFields(),
    watchGetTotalRuleFields(),
    watchGetActivityTemplate(),
    watchDeleteActivityTemplate(),
    watchEditActivityTemplate(),
    watchDuplicateActivityTemplate(),
    watchTemplateToActivity(),
    watchCancelOrder(),
];
export default sagaArray;
