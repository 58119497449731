import { GridSortDirection } from "@mui/x-data-grid";
import { AnyAction, createReducer } from "@reduxjs/toolkit";
// import * as _ from "lodash";
import {
  toggleAddEntryDrawer,
  toggleCloseDrawer,
  toggleRollDrawer,
  toggleDetailsDrawer,
  setSortModel,
  toggleAddLegsDrawer,
  setFilterByAccount,
  setSelectionModel,
  addFilterBySymbol,
  removeFilterBySymbol,
  setFilterByStatus,
  setFilterByDate,
  setFilterBySymbols,
  setDetailsStockChart,
  toggleDetailsStockChart,
  setDetailsDrawer,
  setPageSize,
  setPage,
  setPLChart,
  togglePLChart,
  toggleAddEntryFromOrdersDrawer,
  toggleAddLegsFromOrderDrawer,
  toggleChangeCustomStrateyDialog,
  setJournalTableColumnHidden,
  setJournalTableColumnShown,
  setJournalTableVisibilityModel,
  resetUI,
  setBar,
  toggleAddActivityTemplateDrawer,
  toggleAddActivityDrawer,
  toggleReviewOrderDrawer,
} from "./UI.actions";
import { toggleDeleteDrawer } from "./UI.actions";
import { UIStoreState } from "./UI.types";

const initialState: UIStoreState = {
  addEntryDrawer: false,
  addEntryFromOrdersDrawer: false,
  closeDrawer: false,
  rollDrawer: false,
  detailsDrawer: false,
  deleteDrawer: false,
  entryDetailDialog: false,
  addActivityTemplateDrawer: false,
  addActivityDrawer: false,
  reviewOrderDrawer: false,
  selectionModel: [],
  filterBySymbols: [],
  filterByAccount: "All",
  filterByDates: "",
  filterByStatus: "All",
  sortModel: [{ field: "initialDate", sort: "asc" as GridSortDirection }],
  addLegs: false,
  addLegsFromOrder: false,
  detailsStockChart: false,
  PLChart: false,
  page: 0,
  pageSize: 100,
  changeCustomStrategyDialog: false,
  journalTableColumnHidden: [],
  journalTableColumnShown: [],
  journalTableVisibilityModel: {},

  barShow: false,
  barSeverity: "success",
  barMessage: "",
};

interface UIReducer {
  [x: string]: (
    state: UIStoreState,
    action: AnyAction
  ) => UIStoreState | undefined;
}

const reducer: UIReducer = {
  [resetUI.toString()]: () => {
    return initialState;
  },
  [toggleChangeCustomStrateyDialog.toString()]: (state) => ({
    ...state,
    changeCustomStrategyDialog: !state.changeCustomStrategyDialog,
  }),
  [toggleAddEntryDrawer.toString()]: (state) => ({
    ...state,
    addEntryDrawer: !state.addEntryDrawer,
  }),
  [toggleAddEntryFromOrdersDrawer.toString()]: (state) => ({
    ...state,
    addEntryFromOrdersDrawer: !state.addEntryFromOrdersDrawer,
  }),
  [toggleCloseDrawer.toString()]: (state) => ({
    ...state,
    closeDrawer: !state.closeDrawer,
  }),
  [toggleRollDrawer.toString()]: (state, action) => ({
    ...state,
    rollDrawer: action.payload,
  }),
  [toggleDetailsDrawer.toString()]: (state) => ({
    ...state,
    detailsDrawer: !state.detailsDrawer,
  }),
  [setDetailsDrawer.toString()]: (state, action) => ({
    ...state,
    detailsDrawer: action.payload,
  }),
  [toggleDeleteDrawer.toString()]: (state) => ({
    ...state,
    deleteDrawer: !state.deleteDrawer,
  }),
  [toggleAddLegsDrawer.toString()]: (state, action) => ({
    ...state,
    addLegs: action.payload,
  }),
  [toggleAddLegsFromOrderDrawer.toString()]: (state, action) => ({
    ...state,
    addLegsFromOrder: action.payload,
  }),
  [toggleAddActivityTemplateDrawer.toString()]: (state) => ({
    ...state,
    addActivityTemplateDrawer: !state.addActivityTemplateDrawer,
  }),
  [toggleAddActivityDrawer.toString()]: (state, action) => ({
    ...state,
    addActivityDrawer: action.payload,
  }),
  [toggleReviewOrderDrawer.toString()]: (state, action) => ({
    ...state,
    reviewOrderDrawer: action.payload,
  }),
  [setSelectionModel.toString()]: (state, action) => {
    if (action.payload[0] === state.selectionModel[0]) return state;
    else
      return {
        ...state,
        selectionModel: action.payload,
      };
  },
  [setFilterBySymbols.toString()]: (state, action) => ({
    ...state,
    filterBySymbols: [],
    filterByStatus: "All",
    page: 0,
  }),
  [addFilterBySymbol.toString()]: (state, action) => ({
    ...state,
    filterBySymbols: state.filterBySymbols
      ? state.filterBySymbols.indexOf(action.payload) < 0
        ? [...state.filterBySymbols, action.payload]
        : state.filterBySymbols
      : [action.payload],
    filterByStatus: "All",
    page: 0,
  }),
  [removeFilterBySymbol.toString()]: (state, action) => ({
    ...state,
    filterBySymbols: state.filterBySymbols
      ? state.filterBySymbols.indexOf(action.payload) >= 0
        ? state.filterBySymbols.filter((symbol) => symbol !== action.payload)
        : state.filterBySymbols
      : [],
    filterByStatus: "Open",
    page: 0,
  }),
  [setFilterByStatus.toString()]: (state, action) => ({
    ...state,
    filterByStatus: action.payload,
    page: 0,
  }),
  [setFilterByAccount.toString()]: (state, action) => ({
    ...state,
    filterByAccount: action.payload,
    page: 0,
  }),
  [setFilterByDate.toString()]: (state, action) => ({
    ...state,
    filterByDates: action.payload,
    filterByStatus:
      action.payload === "TodayTrades"
        ? "All"
        : (action.payload as string).startsWith("Exp") || action.payload === ""
        ? "Open"
        : state.filterByStatus,
    page: 0,
  }),
  [setSortModel.toString()]: (state, action) => ({
    ...state,
    sortModel: action.payload,
  }),
  [setDetailsStockChart.toString()]: (state, action) => ({
    ...state,
    // PLChart: false,
    detailsStockChart: action.payload,
  }),
  [toggleDetailsStockChart.toString()]: (state, action) => ({
    ...state,
    // PLChart: false,
    detailsStockChart: !state.detailsStockChart,
  }),
  [setPage.toString()]: (state, action) => ({
    ...state,
    page: action.payload,
  }),
  [setPageSize.toString()]: (state, action) => ({
    ...state,
    pageSize: action.payload,
  }),
  [setPLChart.toString()]: (state, action) => ({
    ...state,
    PLChart: action.payload,
    // detailsStockChart: false,
  }),
  [togglePLChart.toString()]: (state, action) => ({
    ...state,
    PLChart: !state.PLChart,
    // detailsStockChart: false,
  }),
  [setJournalTableColumnHidden.toString()]: (state, action) => ({
    ...state,
    journalTableColumnHidden: action.payload,
  }),
  [setJournalTableColumnShown.toString()]: (state, action) => ({
    ...state,
    journalTableColumnShown: action.payload,
  }),
  [setJournalTableVisibilityModel.toString()]: (state, action) => ({
    ...state,
    journalTableVisibilityModel: action.payload,
  }),
  [setBar.toString()]: (state, action) => ({
    ...state,
    barShow: action.payload.show,
    barSeverity: action.payload.severity,
    barMessage: action.payload.message,
  }),
};

export default createReducer(initialState, reducer);
