import React, { useState } from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import LoaderButton from "components/LoaderButton";
import { Activity, GetCommonDiv, JournalEntry } from "journal-lib";
import { useDispatch, useSelector } from "react-redux";
import { closeEntry, SelectSelectedEntry } from "redux/JournalEntries";
import { selectorCloseDrawer } from "redux/UI/UI.selectors";
import { toggleCloseDrawer } from "redux/UI/UI.actions";
import {
  getMonthDateString,
  getNowDateString,
  gridDateFormatter,
  gridOrderTypeFormatter,
  stringToUTC,
  usePhoneScreenMedia,
  useXsDownMedia,
} from "utils";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { Autocomplete } from "@mui/material";

const useStyles = makeStyles({
  list: {
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
  },
  fullList: {
    width: "auto",
  },
  uppercase: {
    textTransform: "uppercase",
  },
  debit: {
    "& input": {
      color: "red",
    },
  },
  credit: {
    "& input": {
      color: "green",
    },
  },
  noPadding: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
});

export const CloseEntry = ({
  isDialog = true,
  entry_parent,
  setEntry_parent,
  debitCredit_parent,
  setDebitCredit_parent,
  selectionModel_parent,
  setSelectionModel_parent,
}: {
  isDialog?: boolean;
  entry_parent?: Activity;
  setEntry_parent?: React.Dispatch<React.SetStateAction<Activity>>;
  debitCredit_parent?: { value: string; label: string };
  setDebitCredit_parent?: React.Dispatch<
    React.SetStateAction<{ value: string; label: string }>
  >;
  selectionModel_parent?: GridRowId[];
  setSelectionModel_parent?: React.Dispatch<React.SetStateAction<GridRowId[]>>;
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [postToFeed, setPostToFeed] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [debitCredit_, setDebitCredit_] = useState<{
    value: string;
    label: string;
  }>({
    value: "credit",
    label: "Credit",
  });
  const debitCredit: { value: string; label: string } =
    !isDialog && debitCredit_parent ? debitCredit_parent : debitCredit_;
  const setDebitCredit =
    !isDialog && setDebitCredit_parent
      ? setDebitCredit_parent
      : setDebitCredit_;

  const validateForm = {
    price: () => entry.price ?? 0 ?? 0 >= 0,
    selected: () => selectionModel && selectionModel.length > 0,

    all: () => validateForm.price() && validateForm.selected(),
  };

  const selectedEntry: JournalEntry = SelectSelectedEntry();
  const [entry_, setEntry_] = useState<Activity>({
    type: "closing",
    desc: "initial",
    id: "",
    date: new Date(),//getNowDateString(),
    commission: 0,
    price: selectedEntry?.marketPrice
      ? Math.abs(Math.round(selectedEntry?.marketPrice * 100) / 100)
      : 0,
    legs: [],
    note: "",
    externalId: "",
  });
  const entry = !isDialog && entry_parent ? entry_parent : entry_;
  const setEntry = !isDialog && setEntry_parent ? setEntry_parent : setEntry_;
  const initialActivity: Activity = {
    externalId: "",
    type: "closing",
    desc: "",
    id: "",
    date: new Date(),//getNowDateString(),
    commission: 0,
    price: selectedEntry?.marketPrice
      ? Math.abs(Math.round(selectedEntry?.marketPrice * 100) / 100)
      : 0,
    legs: selectedEntry?.position?.legs?.map((leg) => {
      return {
        type: leg.qty > 0 ? "stc" : "btc",
        leg: { ...leg, qty: leg.qty * -1 },
      };
    }),
    note: "",
  };

  const [selectionModel_, setSelectionModel_] = useState<GridRowId[]>(
    selectedEntry
      ? selectedEntry.position.legs.map((leg, index) => index + 1)
      : []
  );
  const selectionModel = isDialog ? selectionModel_ : selectionModel_parent;
  const setSelectionModel = isDialog
    ? setSelectionModel_
    : setSelectionModel_parent;

  if (entry.desc === "initial") {
    setEntry(initialActivity);
    if (selectedEntry?.marketPrice)
      setDebitCredit(
        selectedEntry?.marketPrice >= 0
          ? {
              value: "credit",
              label: "Credit",
            }
          : {
              value: "debit",
              label: "Debit",
            }
      );
    // console.log("initial");
    // if (setSelectionModel) {
    //   setSelectionModel(
    //     selectedEntry.position.legs.map((leg, index) => index + 1)
    //   );
    // }
  }

  const handleFieldChange = (event: any) => {
    if (event && event.target)
      setEntry({
        ...entry,
        [event.target.id]: event.target.value,
      });
  };

  const onQtyChanged = (e: any, params: any) => {
    const newEntry: Activity = {
      ...entry,
      legs: entry.legs.map((activityLeg, index) => ({
        ...activityLeg,
        leg: {
          ...activityLeg.leg,
          qty: index === params.id - 1 ? e.target.value : activityLeg.leg?.qty,
          option: {
            ...activityLeg.leg?.option,
            symbol: activityLeg.leg?.option.symbol ?? "",
            type: activityLeg.leg?.option.type ?? "put",
          },
        },
      })),
    };
    setEntry(newEntry);
    setTotalPrice(
      Math.round(
        (entry.price ?? 0) * getTotalPriceMultiplier(e, params) * 100
      ) / 100
    );
  };

  async function handleSubmit(event: any) {
    if (!selectedEntry.entryId) return;
    setIsLoading(true);
    event.preventDefault();

    let price = totalPrice;
    if (
      selectionModel &&
      selectionModel.length === 1 &&
      entry.legs[+selectionModel[0] - 1].leg?.option.type === "stock"
    )
      price = totalPrice / 100;

    if (validateForm.all() && selectionModel) {
      try {
        dispatch(
          closeEntry({
            entryId: selectedEntry.entryId,
            activity: {
              ...entry,
              legs: selectionModel.map(
                (index) => entry.legs[Number(index) - 1]
              ),
              price: debitCredit.value === "credit" ? price : price * -1,
              date: entry.date,//stringToUTC(entry.date),
            },
            postToFeed: postToFeed,
          })
        );
        setIsLoading(false);
        //   setNewUser(newUser);
      } catch (e) {
        //   onError(e);
        setIsLoading(false);
      }
    } else {
      setShowError(true);
      setIsLoading(false);
    }
  }

  const isXs = useXsDownMedia();
  const headerArray: GridColDef[] = [
    // {
    //   field: "symbol",
    //   headerName: "Symbol",
    //   flex: 0.8,
    //   headerAlign: "center",
    //   align: "center",
    //   cellClassName: classes.uppercase,
    // },
    {
      field: "type",
      headerName: "Type",
      valueFormatter: gridOrderTypeFormatter,
      flex: 0.7,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Exp. Date",
      valueFormatter: gridDateFormatter,
      type: "date",
      flex: 0.7,
    },
    {
      field: "strike",
      headerName: "Strike",
      type: "number",
      flex: 0.6,
    },
    {
      field: "side",
      headerName: "Put / Call",
      flex: 0.6,
    },
    {
      field: "currentQty",
      headerName: "QTY on Hand",
      type: "number",
      flex: 0.7,
    },
    {
      field: "qty",
      headerName: isXs ? "QTY" : "QTY to Close",
      renderCell: (params: GridCellParams) => (
        <TextField
          id="qty"
          type="number"
          value={params.value}
          onChange={(e) => onQtyChanged(e, params)}
          inputProps={{ style: { textAlign: "center" }, inputMode: "numeric" }}
        />
      ),
      flex: isXs ? 0.15 : 0.7,
    },
  ];
  headerArray.forEach((column) => {
    column.headerAlign = "center";
    column.align = "center";
    // column.disableClickEventBubbling = true;
  });

  const rows = entry?.legs?.map((leg, index) => {
    return {
      id: index + 1,
      symbol: selectedEntry?.symbol,
      type: leg.type,
      side: leg.leg?.option.type,
      strike: isXs
        ? `${leg.type.toUpperCase()} ${
            leg.leg?.option.expDate
              ? getMonthDateString(new Date(leg.leg?.option.expDate))
              : ""
          } ${leg.leg?.option.strike} ${leg.leg?.option.type.toUpperCase()}`
        : leg.leg?.option.strike,
      date: leg.leg?.option.expDate,
      currentQty:
        selectedEntry?.position?.legs.length > index
          ? selectedEntry?.position?.legs[index].qty
          : 0,
      qty: leg.leg?.qty,
    };
  });

  const getTotalPriceMultiplier = (
    e: any,
    params: any,
    selectionIds?: GridRowId[]
  ) => {
    const qtyArray = (
      e.target.id === "price"
        ? entry.legs
            .filter(
              (leg, index) =>
                selectionModel &&
                selectionModel.length > 0 &&
                selectionModel.findIndex(
                  (selection) => +selection - 1 === index
                ) >= 0
            )
            .map((leg) => leg.leg?.qty)
        : e.target.id === "selection" && selectionIds
        ? entry.legs
            .filter(
              (leg, index) =>
                selectionIds &&
                selectionIds.length > 0 &&
                selectionIds.findIndex(
                  (selection) => +selection - 1 === index
                ) >= 0
            )
            .map((leg) => leg.leg?.qty)
        : entry.legs
            .filter(
              (leg, index) =>
                selectionModel &&
                selectionModel.length > 0 &&
                selectionModel.findIndex(
                  (selection) => +selection - 1 === index
                ) >= 0
            )
            .map((leg, index) =>
              index === params.id - 1 ? e.target.value : leg.leg?.qty
            )
    ).filter((qty) => qty !== 0 && qty !== "");

    if (qtyArray.length > 1) {
      return Math.abs(GetCommonDiv(qtyArray));
    } else if (qtyArray.length === 1) {
      return Math.abs(+qtyArray[0]);
    } else {
      return 0;
    }
  };

  const list = (anchor: string) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <Grid
        container
        spacing={3}
        style={{ padding: "0 20px" }}
        className={clsx({ [classes.noPadding]: !isDialog })}
      >
        {isDialog ? (
          <Grid
            container
            spacing={3}
            item
            xs={12}
            style={{ padding: "12px 12px 12px 20px" }}
          >
            <Grid item xs={isXs ? 12 : 6}>
              <TextField
                fullWidth
                id="date"
                label="Closing Date"
                type="date"
                value={entry.date}
                onChange={(e) => {
                  handleFieldChange(e);
                }}
              />
            </Grid>
            <Grid item xs={isXs ? 4 : 2}>
              <Autocomplete
                value={debitCredit}
                onChange={(event, newValue: any) => {
                  if (newValue) {
                    setDebitCredit(newValue);
                  } else {
                    setDebitCredit({ value: "", label: "" });
                  }
                }}
                autoSelect
                id="debitCredit"
                options={[
                  { label: "Debit", value: "debit" },
                  { label: "Credit", value: "credit" },
                ]}
                getOptionLabel={(option: any) => option.label}
                renderInput={(params) => (
                  <TextField {...params} label="Debit/Credit" />
                )}
              />
              {/* <FormControl fullWidth>
                <InputLabel id="debitCredit-label">Debit/Credit</InputLabel>
                <Select
                  fullWidth
                  labelId="Debit/Credit"
                  id="debitCredit"
                  value={debitCredit}
                  onChange={(event) => setDebitCredit(event.target.value)}
                >
                  <MenuItem value={"debit"}>Debit</MenuItem>
                  <MenuItem value={"credit"}>Credit</MenuItem>
                </Select>
              </FormControl> */}
            </Grid>
            <Grid item xs={isXs ? 4 : 2}>
              <TextField
                className={clsx({
                  [classes.debit]: debitCredit.value === "debit",
                  [classes.credit]: debitCredit.value === "credit",
                })}
                fullWidth
                id="price"
                label="Closing Price"
                type="number"
                value={entry.price ?? 0}
                onChange={(e) => {
                  handleFieldChange(e);

                  setTotalPrice(
                    Math.round(
                      +e.target.value * getTotalPriceMultiplier(e, null) * 100
                    ) / 100
                  );
                }}
                error={showError && !validateForm.price()}
                helperText={
                  showError && !validateForm.price()
                    ? "Price must be positive"
                    : ""
                }
                inputProps={{ inputMode: "decimal", step: "0.01" }}
              />
            </Grid>
            <Grid item xs={isXs ? 4 : 2}>
              <TextField
                className={clsx({
                  [classes.debit]: debitCredit.value === "debit",
                  [classes.credit]: debitCredit.value === "credit",
                })}
                fullWidth
                id="totalPrice"
                label="Total Price"
                type="number"
                value={totalPrice}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        ) : (
          <div />
        )}

        {isDialog ? (
          <Grid xs={12} item>
            <TextField
              fullWidth
              id="note"
              label="Note"
              value={entry.note}
              onChange={handleFieldChange}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <DataGrid
            disableColumnMenu
            autoHeight
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              if (setSelectionModel) {
                setSelectionModel([...newSelection]);

                setTotalPrice(
                  Math.round(
                    (entry.price ?? 0) *
                      getTotalPriceMultiplier(
                        { target: { id: "selection", value: "" } },
                        null,
                        [...newSelection]
                      ) *
                      100
                  ) / 100
                );
              }
            }}
            rowSelectionModel={selectionModel}
            columns={headerArray.filter((header) =>
              isXs
                ? header.field === "qty" || header.field === "strike"
                  ? true
                  : false
                : true
            )}
            rows={rows}
            hideFooter={true}
          />
        </Grid>
      </Grid>
    </div>
  );

  const onClosing = () => {
    dispatch(toggleCloseDrawer());
  };

  const reset = () => {
    setSelectionModel_([]);
    setShowError(false);
  };

  const isOpen = useSelector(selectorCloseDrawer);

  const isPhoneScreen = usePhoneScreenMedia();
  return isDialog ? (
    <Dialog
      fullScreen={isPhoneScreen}
      open={isOpen}
      onClose={onClosing}
      fullWidth
      maxWidth="md"
      TransitionProps={{
        onEnter: () => {
          setEntry({
            type: "closing",
            desc: "initial",
            id: "",
            date: new Date(),//getNowDateString(),
            commission: 0,
            price: selectedEntry?.marketPrice
              ? Math.abs(Math.round(selectedEntry?.marketPrice * 100) / 100)
              : 0,
            legs: [],
            note: "",
            externalId: "",
          });

          if (setSelectionModel) {
            setSelectionModel(
              selectedEntry.position.legs.map((leg, index) => index + 1)
            );
          }
        },

        onExited: reset,
      }}
    >
      <form
        noValidate
        className="BillingForm"
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        <DialogTitle id="add-entry-dialog-title">
          {`Close Entry - ${selectedEntry.symbol}`}
        </DialogTitle>

        <DialogContent
          style={{ overflow: "hidden" }}
          className={clsx({ [classes.noPadding]: isXs })}
        >
          {list("right")}
        </DialogContent>

        <DialogActions>
          <Box>
            Post activity to news feed?
            <Checkbox
              value={postToFeed}
              defaultChecked
              color="primary"
              inputProps={{ "aria-label": "secondary checkbox" }}
              onChange={(event, checked) => setPostToFeed(checked)}
            />
          </Box>
          <LoaderButton
            type="submit"
            isLoading={isLoading}
            color="primary"
            // disabled={!validateForm.all()}
          >
            Close Entry
          </LoaderButton>
          <Button color="secondary" onClick={onClosing}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  ) : (
    list("right")
  );
};

export default CloseEntry;
