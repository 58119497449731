import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import LoaderButton from "components/LoaderButton";
import { selectorAddActivityTemplateDrawer } from "redux/UI/UI.selectors";
import { toggleAddActivityTemplateDrawer } from "redux/UI/UI.actions";
import { usePhoneScreenMedia } from "utils";
import { addActivityTemplate, editActivityTemplate } from "redux/SmartOptionsAI";
import { ActivityTemplateLegs, ActivityTemplateTabItem } from "components/SmartOptionsAI/ActivityTemplate/ActivityTemplateLegs";
import _ from "lodash";
import { ActivityTemplate } from "journal-lib";

interface AddActivityTemplateProps {
  activityTemplate?: ActivityTemplate | null;
}

const AddActivityTemplate = ({ activityTemplate }: AddActivityTemplateProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectorAddActivityTemplateDrawer);
  const isPhoneScreen = usePhoneScreenMedia();

  const [showError, setShowError] = useState(false);
  const [localName, setLocalName] = useState(activityTemplate?.name ?? "");
  const [localSymbol, setLocalSymbol] = useState(activityTemplate?.symbol ?? "");
  const [localDesc, setLocalDesc] = useState(activityTemplate?.desc ?? "");
  const [isLoading, setIsLoading] = useState(false);

  const getTabs = (activityTemplate?: ActivityTemplate | null): ActivityTemplateTabItem[] => {
    return activityTemplate?.legTemplates?.length
      ? [
        ...activityTemplate.legTemplates.map((legTemplate, index) => ({
          id: `tab${index + 1}`,
          label: `Leg ${index + 1}`,
          legTemplate: _.cloneDeep(legTemplate),
        })),
        {
          id: "tabTotal",
          label: "Total Rules",
          legTemplate: {
            qty: 1,
            type: "put",
            side: "sto",
            legRule: _.cloneDeep(activityTemplate?.totalRule) || undefined,
          },
        },
      ]
      : [
        {
          id: "tab1",
          label: "Leg 1",
          legTemplate: { qty: 1, type: "put", side: "sto", legRule: undefined },
        },
        {
          id: "tabTotal",
          label: "Total Rules",
          legTemplate: { qty: 1, type: "put", side: "sto", legRule: undefined },
        },
      ];
  }

  const [tabs, setTabs] = useState<ActivityTemplateTabItem[]>(
    getTabs(activityTemplate)
  );

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    setLocalName(activityTemplate?.name ?? "");
    setLocalSymbol(activityTemplate?.symbol ?? "");
    setLocalDesc(activityTemplate?.desc ?? "");
    setTabs(getTabs(activityTemplate));
  }, [
    activityTemplate
  ]);

  const addTab = () => {
    const newTabIndex = tabs.length;
    const newTab: ActivityTemplateTabItem = {
      id: `tab${newTabIndex}`,
      label: `Leg ${newTabIndex}`,
      legTemplate: {
        qty: 1,
        side: "sto",
        type: "put",
        legRule: _.cloneDeep(tabs[0]?.legTemplate?.legRule || undefined),
      },
    };
    setTabs([...tabs.slice(0, -1), newTab, tabs[tabs.length - 1]]);
    setActiveTab(tabs.length); // Switch to the new tab
  };

  const updateActiveTab = (index: number) => {
    setActiveTab(index);
  };

  // Function to update a specific tab's data
  const updateTabData = (id: string, fieldName: string, newData: any) => {
    console.log("updateTabData", { id, fieldName, newData });
    setTabs(
      tabs.map(tab => {
        if (tab.id == id) {
          // if (tab.id == 'tabTotal') {
          //   if (fieldName == 'legRule') {
          //     tab.legTemplate.legRule = newData;
          //   }
          // } else 
          // {
          tab.legTemplate = {
            ...tab.legTemplate,
            [fieldName]: newData
          }
          // }
          return tab;
        } else {
          return tab;
        }
      })
    );
    // setTabs((prevTabs) => {
    //   const updatedTabs = [...prevTabs];
    //   updatedTabs[index] = {
    //     ...updatedTabs[index],
    //     legTemplate: {
    //       ...updatedTabs[index].legTemplate,
    //       [fieldName]: newData,
    //     },
    //   };
    //   console.log("Updated tab data:", updatedTabs);
    //   return updatedTabs;
    // });
  };

  const deleteTab = (id: string) => {
    const newTabs = tabs.filter((tab) => tab.id !== id);
    setActiveTab(0);
    setTabs(newTabs);
  };


  const getAddBody = () => ({
    activityTemplate: {
      id: "",
      identityId: "",
      name: localName,
      symbol: localSymbol.toUpperCase(),
      desc: localDesc,
      legTemplates: tabs.slice(0, -1).map((tab) => tab.legTemplate),
      totalRule: tabs[tabs.length - 1].legTemplate.legRule,
    },
  });

  const getEditBody = () => ({
    activityTemplate: {
      id: String(activityTemplate?.id),
      identityId: String(activityTemplate?.identityId),
      name: localName,
      symbol: localSymbol.toUpperCase(),
      desc: localDesc,
      legTemplates: tabs.slice(0, -1).map((tab) => tab.legTemplate),
      totalRule: tabs[tabs.length - 1].legTemplate.legRule,
    }
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (activityTemplate) {
        dispatch(editActivityTemplate(getEditBody()));
      }
      else {
        dispatch(addActivityTemplate(getAddBody()));
      }

    } finally {
      setIsLoading(false);
    }
  };

  const onClosing = () => {
    dispatch(toggleAddActivityTemplateDrawer());
    setIsLoading(false);
    setShowError(false);
  };

  const reset = () => {
    setLocalName("");
    setLocalSymbol("");
    setLocalDesc("");
    setTabs([
      {
        id: "tab1",
        label: "Leg 1",
        legTemplate: { qty: 1, type: "put", side: "sto", legRule: undefined },
      },
      {
        id: "tabTotal",
        label: "Total Rules",
        legTemplate: { qty: 1, type: "put", side: "sto", legRule: undefined },
      },
    ]);
  };

  return (
    <Dialog
      key={activityTemplate?.id || "new"}
      open={isOpen}
      onClose={onClosing}
      fullWidth
      maxWidth="md"
      fullScreen={isPhoneScreen}
      TransitionProps={{
        onExited: reset,
      }}
    >
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <DialogTitle>Option Template</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
          <Grid container spacing={3} style={{ padding: "0 20px" }}>
            <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
              <TextField fullWidth label="Name" value={localName} onChange={(e) => setLocalName(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={3} sx={{ mt: 2 }}>
              <TextField fullWidth label="Symbol" value={localSymbol} onChange={(e) => setLocalSymbol(e.target.value)} />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
              <TextField fullWidth label="Description" value={localDesc} onChange={(e) => setLocalDesc(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <ActivityTemplateLegs tabs={tabs} activeTab={activeTab} onTabDataChange={updateTabData} onAddTab={addTab} onActiveTabChange={updateActiveTab} onDeleteTab={deleteTab} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoaderButton type="submit" isLoading={isLoading} color="primary">
            {activityTemplate ? "Save" : "Add"}
          </LoaderButton>
          <Button color="secondary" onClick={onClosing}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddActivityTemplate;
