import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Forms } from "components";
import { useFormFields } from "libs/hooksLib";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorAccountArray } from "redux/Account/Account.selectors";
import { selectorBrokerage, selectorUser } from "redux/User";
import { getGridSize } from "utils";
import { Create, Delete, Link } from "@mui/icons-material";
import {
  addAccount,
  deleteAccount,
  editAccount,
  getAccount,
} from "redux/Account";
import { editUser, getUser } from "redux/User/User.actions";
import { API } from "aws-amplify";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "20px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 320,
    },
  })
);

const StyleGrid = withStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "5px 10px",
    },
  })
)(Grid);

const StyledHeader = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderBottom: "1px solid rgba(0,0,0,.125)",
    },
  })
)(CardHeader);

export const UserSetting = () => {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    dispatch(getUser());
    dispatch(getAccount());
  }, [dispatch]);
  const classes = useStyle();
  const user = useSelector(selectorUser);
  const accountArray = useSelector(selectorAccountArray);
  const brokerArray = useSelector(selectorBrokerage);
  const [fields, setFields] = useFormFields({
    name: user.name,
    username: user.userName,
    discordName: user.discordUserName,
    accounts: accountArray,
    brokers: brokerArray,
    phoneNumber: user.phoneNumber,
  });
  const [editAccountIndex, setEditAccountIndex] = useState(-1);

  const [isLogin, setIsLogin] = useState(false);
  const [signinDialogOpen, setSigninDialogOpen] = useState(false);
  const [brokerAccounts, setBrokerAccounts] = useState<any[]>();

  const [linkAccountDialogOpen, setLinkAccountDialogOpen] = useState(false);
  const [linkAccount, setLinkAccountDesc] = useState<any>();
  const [selectedLinkAccount, setSelectedLinkAccount] = useState("");

  const onSignin = async (event: any) => {
    console.log("onSignin", event);

    fetch("https://api.tastyworks.com/sessions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": "trader-journal/0.1.0",
        Accept: "application/json",
      },
      body: JSON.stringify({
        login: userName,
        password: password,
        "remember-me": true,
      }),
    }).then(
      (successRes) => {
        if (successRes.ok) {
          console.log("login success", successRes);
          successRes.json().then((body) => {
            console.log("body", body);
            console.log("use", user);
            const rememberToken = body.data["remember-token"];
            let tastyProvider: any;
            user.brokerageProviders?.map((broker) => {
              if (broker.marketDataProvider === "tastyworks") {
                tastyProvider = broker;
              }
            });
            API.put("optionOrder", "/user/setDataProviderCredential", {
              body: {
                description: "tastyworks",
                marketDataProvider: "tastyworks",
                username: userName,
                id: tastyProvider?.id,
                rememberToken: rememberToken,
              },
            }).then(() => {
              API.get(
                "optionOrder",
                `/user/accounts/${tastyProvider.id}`,
                {}
              ).then(
                (brokerageAccounts) => {
                  if (brokerageAccounts && brokerageAccounts.length > 0) {
                    setIsLogin(true);
                    setBrokerAccounts(brokerageAccounts);
                  } else {
                    setIsLogin(false);
                  }
                },
                (error) => {
                  console.log("getBrokerAccount error: ", error);
                  setIsLogin(false);
                }
              );
            });
          });
        }
      },
      (rejectRes) => {
        console.log("login call failed");
      }
    )
      .catch((error) => {
        // Log error details
        // console.error(" API error:", error);

        // Optional: Show user-friendly message or handle specific error codes
        if (error.response) {
          // console.error("Status code:", error.response.status);
          if (error.response.data) {
            const errorCode = error.response.data.errorCode;
            const errorMsg = error.response.data.errorMsg;
            console.error(errorMsg);
          }

        } else if (error.message) {
          console.error(error.message);
        }
      });
    // console.log("response", response);

    // if (!response.ok) { /* Handle */ }

    // // If you care about a response:
    // if (response.body !== null) {
    //   // body is ReadableStream<Uint8Array>
    //   // parse as needed, e.g. reading directly, or
    //   const asString = new TextDecoder("utf-8").decode(response.body);
    //   // and further:
    //   const asJSON = JSON.parse(asString);  // implicitly 'any', make sure to verify type on runtime.
    // }

    setSigninDialogOpen(false);
  };

  useEffect(() => {
    if (user) {
      let tastyProvider: any;
      user.brokerageProviders?.map((broker) => {
        if (broker.marketDataProvider === "tastyworks") {
          tastyProvider = broker;
        }
      });
      if (tastyProvider) {
        API.get("optionOrder", `/user/accounts/${tastyProvider.id}`, {}).then(
          (brokerageAccounts) => {
            if (brokerageAccounts && brokerageAccounts.length > 0) {
              setIsLogin(true);
              setBrokerAccounts(brokerageAccounts);
            } else {
              setIsLogin(false);
            }
          },
          (error) => {
            console.log("getBrokerAccount error: ", error);
            setIsLogin(false);
          }
        );
      }
    }
  }, [user]);

  const handleLinkAccountChanged = (event: any) => {
    setSelectedLinkAccount(event.target.value);
  };
  const handleLinkAccountLinked = () => {
    let tastyProvider: any;
    user.brokerageProviders?.map((broker) => {
      if (broker.marketDataProvider === "tastyworks") {
        tastyProvider = broker;
      }
    });
    API.put(
      "optionOrder",
      `/account/linkBrokerageAccount/${linkAccount.value}`,
      {
        body: {
          brokerageProviderId: tastyProvider.id,
          brokerageAccountId: selectedLinkAccount,
        },
      }
    )
      .then(() => dispatch(getAccount()))
      .finally(() => {
        setLinkAccountDialogOpen(false);
      });
  };
  // console.log("account array ", accountArray);
  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <StyleGrid xs={12} item>
          <Card>
            <form
              onSubmit={(event: any) => {
                event.preventDefault();
                dispatch(
                  editUser({
                    name: fields.name,
                    discordUserName: fields.discordName,
                  })
                );
              }}
            >
              <StyledHeader
                title="User Information"
                action={
                  <Button
                    type="submit"
                    color="primary"
                    style={{ margin: "7px 6px -7px 0" }}
                  >
                    Save
                  </Button>
                }
              />
              <CardContent>
                <Forms
                  includeFormTag={false}
                  formArray={[
                    {
                      fieldProps: {
                        fullWidth: true,
                        id: "name",
                        label: "Display Name",
                        value: fields.name,
                        type: "text",
                        onChange: setFields,
                      },
                      gridSize: getGridSize(12),
                    },
                    {
                      fieldProps: {
                        fullWidth: true,
                        id: "discordName",
                        label: "Discord ID",
                        value: fields.discordName,
                        onChange: setFields,
                        type: "text",
                        helperText:
                          "This allow us to @ mention you on Discord if someone @ or mention you in news feed.",
                      },
                      gridSize: getGridSize(12),
                    },
                    {
                      fieldProps: {
                        fullWidth: true,
                        id: "phoneNumber",
                        label: "Phone Number",
                        value: fields.phoneNumber,
                        onChange: setFields,
                        type: "text",
                        helperText: "Verify your phone to received SMS update.",
                      },
                      gridSize: getGridSize(12),
                    },
                  ]}
                />
              </CardContent>
            </form>
          </Card>
        </StyleGrid>
        <StyleGrid xs={12} item>
          <Card>
            <StyledHeader title="Tasty Trade" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} item container>
                  <Grid item xs={3}>
                    <Typography variant="subtitle1" gutterBottom>
                      Status: {isLogin ? "Signed in" : "Signed Out"}
                    </Typography>
                  </Grid>
                  <Grid item style={isLogin ? { display: "none" } : {}}>
                    <Button
                      variant="contained"
                      style={{ margin: "-3px 0 0 0" }}
                      onClick={() => {
                        setSigninDialogOpen(true);
                      }}
                    >
                      Please Sign in
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </StyleGrid>
        <StyleGrid xs={12} item>
          <Card>
            <StyledHeader title="Trading Accounts" />
            <CardContent>
              <Grid container spacing={3}>
                <Grid xs={12} item>
                  <form
                    onSubmit={(e: any) => {
                      e.preventDefault();
                      dispatch(
                        addAccount({
                          description: e.target[0].value,
                        })
                      );
                      e.target[0].value = "";
                    }}
                  >
                    <TextField
                      fullWidth
                      type="text"
                      label="Account Description"
                      variant="outlined"
                      helperText="To add a new account, enter the new account description."
                    />
                  </form>
                  <List>
                    {accountArray && accountArray.length > 0
                      ? accountArray.map((account, index) => (
                        <ListItem>
                          {editAccountIndex === index ? (
                            <form
                              onSubmit={(e: any) => {
                                e.preventDefault();
                                dispatch(
                                  editAccount({
                                    id: account.value,
                                    description: e.target[0].value,
                                    futureCommissionFee: {
                                      perOrderFee: 0,
                                      perContractOpenFee: 0,
                                      perContractCloseFee: 0,
                                      maxPerLeg: 0,
                                    },
                                    optionCommissionFee: {
                                      perOrderFee: 0,
                                      perContractOpenFee: 0,
                                      perContractCloseFee: 0,
                                      maxPerLeg: 0,
                                    },
                                    startValue: { "2021": 0 },
                                    stockCommissionFee: {
                                      perOrderFee: 0,
                                      perContractOpenFee: 0,
                                      perContractCloseFee: 0,
                                      maxPerLeg: 0,
                                    },
                                  })
                                );
                                setEditAccountIndex(-1);
                              }}
                              style={{ width: "100%" }}
                            >
                              <TextField
                                // size="small"
                                autoFocus
                                fullWidth
                                defaultValue={account.label}
                                type="text"
                              />
                            </form>
                          ) : (
                            <ListItemText
                              primary={`${account.label}: ${account.brokerageAccount
                                  ?.brokerageAccountId ?? "Unlinked"
                                }`}
                            />
                          )}

                          <ListItemSecondaryAction>
                            <Tooltip title="Edit Name">
                              <IconButton
                                edge="end"
                                onClick={() => setEditAccountIndex(index)}
                                size="large"
                              >
                                <Create />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Link Tasty Trade Account">
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setLinkAccountDesc(account);
                                  setLinkAccountDialogOpen(true);
                                }}
                                size="large"
                              >
                                <Link />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete Account">
                              <IconButton
                                edge="end"
                                onClick={() =>
                                  dispatch(deleteAccount(account.value))
                                }
                                size="large"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            {/* <IconButton
                              edge="end"
                              onClick={() =>
                                dispatch(deleteAccount(account.value))
                              }
                            >
                              <DeleteIcon />
                            </IconButton> */}
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                      : null}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </StyleGrid>
      </Grid>
      <Dialog open={signinDialogOpen} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>SIGN IN tastytrade</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} style={{ margin: "0 23px 0 23px" }}>
              <TextField
                fullWidth
                type="text"
                label="Username"
                variant="outlined"
                value={userName}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} style={{ margin: "23px  23px 0px 23px" }}>
              <TextField
                fullWidth
                type="Password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSigninDialogOpen(false)}>Cancel</Button>
          <Button onClick={onSignin}>Sign In</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={linkAccountDialogOpen} maxWidth={"sm"} fullWidth={true}>
        <DialogTitle>
          Link {linkAccount?.label} to TastyTrade account
        </DialogTitle>
        <DialogContent>
          <form className={classes.container}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select a TastyTrade account
              </InputLabel>
              <Select
                labelId="tasty trade accounts"
                id="tasty trade accounts"
                onChange={handleLinkAccountChanged}
                fullWidth
              >
                {brokerAccounts?.map((account: any) => (
                  <MenuItem value={account.accountId}>
                    {account.accountId}: {account.displayName} (
                    {account.marginOrCash})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLinkAccountDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleLinkAccountLinked}>Link Account</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
