import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";
const theme = createTheme();

export const useLegSettingStyles = makeStyles(() => ({
    queryBuilder: {
        "& .e-query-builder": {
            border: "none !important",
            boxShadow: "none !important",
            backgroundColor: "transparent",
        },
    },
}));
