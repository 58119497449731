import { useEffect, useState } from "react";
import { Tabs, Tab, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useLegRuleTabsStyles } from "./ActivityTemplateLegs.styles";
import { ActivityTemplateLegSetting } from "components/SmartOptionsAI/ActivityTemplate/ActivityTemplateLegSetting/ActivityTemplateLegSetting.component";
import { LegTemplate } from "journal-lib";

export interface ActivityTemplateTabItem {
  id: string;
  label: string;
  legTemplate: LegTemplate;
}

interface ActivityTemplateLegsProps {
  tabs: ActivityTemplateTabItem[];
  activeTab: number;
  onAddTab: () => void;
  onActiveTabChange: (index: number) => void;
  onTabDataChange: (id: string, fieldName: string, newData: any) => void;
  onDeleteTab: (id: string) => void;
}

export const ActivityTemplateLegs = ({ tabs, activeTab, onAddTab, onActiveTabChange, onTabDataChange, onDeleteTab }: ActivityTemplateLegsProps) => {
  const classes = useLegRuleTabsStyles();

  const handleAddTab = () => {
    onAddTab();
  };

  useEffect(() => {
    if (activeTab >= tabs.length) {
      onActiveTabChange(Math.max(0, tabs.length - 1)); // Set activeTab to last available tab or 0 if empty
    }
  }, [tabs, activeTab, onActiveTabChange]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Delete" && activeTab > 0 && activeTab < tabs.length - 1 && tabs.length > 0 && tabs[activeTab]) {
        const shouldDelete = window.confirm("Delete this leg?");
        if (shouldDelete) {
          onDeleteTab(tabs[activeTab].id);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [tabs, activeTab, onDeleteTab]);

  return (
    <Box className={classes.tabContainer}>
      {/* Tab Navigation */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tabs
          value={Math.min(activeTab, tabs.length - 1)}
          onChange={(_event: React.SyntheticEvent, newValue: number) => onActiveTabChange(newValue)}
          textColor="primary"
          indicatorColor="primary"
          className={classes.tabIndicator}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab.id}
              label={tab.label}
              className={`${classes.tab} ${activeTab === index ? classes.selectedTab : ""}`}
            />
          ))}
        </Tabs>
        <IconButton onClick={handleAddTab} sx={{ ml: 1 }}>
          <AddIcon />
        </IconButton>
      </Box>

      {/* Tab Content */}
      <Box className={classes.tabContent}>
        <ActivityTemplateLegSetting key={`${tabs[activeTab]?.id}-${JSON.stringify(tabs[activeTab]?.legTemplate)}`}
          isTotal={tabs[activeTab]?.id.includes("Total")}
          legTemplate={tabs[activeTab]?.legTemplate}
          onLegTemplateChange={(fieldName, newData) => onTabDataChange(tabs[activeTab]?.id, fieldName, newData)} />
      </Box>
    </Box>
  );
}
