import React, { useEffect, useState } from "react";
import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
    Menu, MenuItem,
    FormControl,
    InputLabel,
    Select,
    TextField,
    Snackbar,
    Alert,
    CircularProgress
} from "@mui/material";
import { Add, Edit, Delete, MoreVert, ContentCopy, PostAdd } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toggleAddActivityDrawer, toggleAddActivityTemplateDrawer } from "redux/UI";
import AddActivityTemplate from "components/SmartOptionsAI/ActivityTemplate/AddActivityTemplate/AddActivityTemplate.component";
import { ActivityTemplate, AdvancedActivity } from "journal-lib";
import { deleteActivityTemplate, duplicateActivityTemplate, getActivityTemplates, getLegRuleFields, getTotalRuleFields, selectorActivityTemplates, templateToActivity } from "redux/SmartOptionsAI";
import { getAccount, selectorAccountStore } from "redux/Account";
import { API } from "aws-amplify";
import AddActivity from "components/SmartOptionsAI/Activity/AddActivity/AddActivity.component";
import ReviewOrder from "components/SmartOptionsAI/Activity/ReviewOrder/ReviewOrder.component";

const ActivityTemplateList = () => {
    const accounts = useSelector(selectorAccountStore);
    const accountList: { value: string; label: string }[] = Object.keys(
        accounts
    ).map((accountId) => {
        return {
            value: accountId,
            label: accounts[accountId].description,
        };
    });

    const activityTemplates = useSelector(selectorActivityTemplates);

    const [templates, setTemplates] = useState<ActivityTemplate[]>(activityTemplates);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<ActivityTemplate | null>(null);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
    const [openActivityDialog, setOpenActivityDialog] = useState(false);
    const [selectedAccountId, setSelectedAccountId] = useState("");
    const [size, setSize] = useState(1);

    const [newActivity, setNewActivity] = useState<AdvancedActivity | null>(null);
    const [modifiedActivity, setModifiedActivity] = useState<AdvancedActivity | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActivityTemplates({}));
        dispatch(getLegRuleFields());
        dispatch(getTotalRuleFields());
        dispatch(getAccount());
    }, [
        dispatch,
    ]);

    useEffect(() => {
        setTemplates(activityTemplates);
    }, [
        activityTemplates,
    ]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const handleDeleteConfirm = () => {
        if (deleteIndex !== null) {
            dispatch(deleteActivityTemplate({ id: templates[deleteIndex].id }));
            // setTemplates(prevTemplates => prevTemplates.filter((_, index) => index !== selectedIndex));
            setSelectedIndex(null);
            setDeleteIndex(null);
        }
    };

    const handleCreateActivity = () => {
        if (selectedTemplate) {

            setIsLoading(true); // Start loading

            // dispatch(templateToActivity({ id: selectedTemplate.id, accountId: selectedAccountId, size }));
            API.post("optionOrder", "/smartAI/templateToActivity", {
                body: { id: selectedTemplate.id, accountId: selectedAccountId, size },
            }).then((res) => {
                if (res) {
                    console.log("templateToActivity", res);
                    setNewActivity(res);
                    dispatch(toggleAddActivityDrawer(true));
                }

            })
                .catch((error) => {
                    console.log("API Error", error);
                    if (error.response) {
                        // console.error("Status code:", error.response.status);
                        if (error.response.data) {
                            const data = error.response.data;
                            if (typeof data === 'string') {
                                setErrorMessage(data);
                                setShowSnackbar(true);
                            } else {
                                const errorCode = data.errorCode;
                                const errorMsg = data.errorMsg;
                                // console.error(errorMsg);
                                setErrorMessage(errorMsg);
                                setShowSnackbar(true);
                            }
                        }

                    } else if (error.message) {
                        // console.error(error.message);
                        setErrorMessage(error.message);
                        setShowSnackbar(true);
                    }
                }).finally(() => {
                    setIsLoading(false); // End loading
                    setOpenActivityDialog(false);
                    setSelectedAccountId("");
                    setSize(1);
                });
        }
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                <Button variant="contained" startIcon={<Add />} onClick={() => {
                    setSelectedTemplate(null);
                    dispatch(toggleAddActivityTemplateDrawer())
                }}>
                    Add
                </Button>
            </div>
            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates?.map((template, index) => (
                            <TableRow key={template.id}>
                                <TableCell>{template.name}</TableCell>
                                <TableCell>{template.symbol}</TableCell>
                                <TableCell>{template.desc}</TableCell>
                                <TableCell>{new Date(template.createdDate ?? "").toLocaleDateString()}</TableCell>
                                <TableCell>
                                    {/* More (three-dot) menu */}
                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dropdown Menu for Edit/Delete */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => {
                    if (selectedIndex !== null && templates[selectedIndex]) {
                        setSelectedTemplate({ ...templates[selectedIndex] }); // Set the selected template
                        setOpenActivityDialog(true);
                        handleMenuClose();
                    }
                    handleMenuClose();
                }}>
                    <PostAdd fontSize="small" style={{ marginRight: 10 }} /> Create Order
                </MenuItem>
                <MenuItem onClick={() => {
                    if (selectedIndex !== null && templates[selectedIndex]) {
                        setSelectedTemplate({ ...templates[selectedIndex] }); // Set the selected template
                        dispatch(toggleAddActivityTemplateDrawer());
                    }
                    handleMenuClose();
                }}>
                    <Edit fontSize="small" style={{ marginRight: 10 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    if (selectedIndex !== null && templates[selectedIndex]) {
                        setSelectedTemplate({ ...templates[selectedIndex] }); // Set the selected template
                        if (templates[selectedIndex].id)
                            dispatch(duplicateActivityTemplate({ id: templates[selectedIndex].id }));
                    }
                    handleMenuClose();
                }}>
                    <ContentCopy fontSize="small" style={{ marginRight: 10 }} /> Duplicate
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteIndex(selectedIndex);
                    handleMenuClose();
                }} color="error">
                    <Delete fontSize="small" style={{ marginRight: 10 }} /> Delete
                </MenuItem>
            </Menu>

            {/* Single Confirmation Dialog */}
            <Dialog open={deleteIndex !== null} onClose={() => setDeleteIndex(null)}>
                <DialogTitle>Delete Template?</DialogTitle>
                <DialogContent>Are you sure you want to delete this template?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteIndex(null)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openActivityDialog} onClose={() => setOpenActivityDialog(false)}>
                <DialogTitle>Create Order</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="dense">
                        <InputLabel>Account ID</InputLabel>
                        <Select value={selectedAccountId} onChange={(e) => setSelectedAccountId(e.target.value)}>
                            {accountList.map(account => (
                                <MenuItem key={account.value} value={account.value}>{account.label}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        label="Size"
                        type="number"
                        value={size}
                        onChange={(e) => setSize(Number(e.target.value))}
                        margin="dense"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenActivityDialog(false)}>Cancel</Button>
                    <Button onClick={handleCreateActivity} color="primary" disabled={isLoading} startIcon={isLoading && <CircularProgress size={20} />}>Create</Button>
                </DialogActions>
            </Dialog>

            <AddActivityTemplate activityTemplate={selectedTemplate} />
            <AddActivity activity={newActivity} setActivity={setModifiedActivity} />
            <ReviewOrder activity={modifiedActivity} setActivity={setNewActivity} />

            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={() => setShowSnackbar(false)}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ActivityTemplateList;
