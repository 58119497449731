import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import journalEntry from "./JournalEntries/JournalEntries.reducer";
import accounts from "./Account/Account.reducers";
import UI from "./UI/UI.reducers";
import User from "./User/User.reducers";
import Feed from "./Feed/Feed.reducers";
import storage from "redux-persist/lib/storage";
import stats from "./Stats/Stats.reducers";
import stock from "./Stock/Stock.reducers";
import analysis from "./Analysis/Analysis.reducers";
import smartOptionAI from "./SmartOptionsAI/SmartOptionsAI.reducers";

export const persistConfig = {
  key: "root",
  storage,
  // whitelist: ["cart"],
  // blacklist: ["analysis"],
};

const rootReducer = combineReducers({
  journalEntry: journalEntry,
  accounts: accounts,
  ui: UI,
  user: User,
  feed: Feed,
  stats: stats,
  stock: stock,
  analysis: analysis,
  smartOptionAI: smartOptionAI,
});

export default persistReducer(persistConfig, rootReducer);
