import makeStyles from "@mui/styles/makeStyles";
import { createTheme } from "@mui/material";
const theme = createTheme();

export const useLegRuleTabsStyles = makeStyles(() => ({
  tabContainer: {
    width: "100%",
    margin: "auto",
    // padding: "16px",
  },
  tabIndicator: {
    "& .MuiTabs-indicator": { backgroundColor: "#345c85", height: 3 },
  },
  tab: {
    textTransform: "none",
    fontWeight: 600,
    color: "gray",
    fontFamily: `"Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
    fontSize: "14px",
  },
  selectedTab: {
    color: "#345c85",
  },
  tabContent: {
    marginTop: "12px",
    // padding: "16px",
    border: "1px solid #ddd",
    borderRadius: "8px",
    boxShadow: "1px 1px 5px rgba(0,0,0,0.1)",
  },
}));

