import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import { OptionChains } from "./OptionChains/OptionChains";
import Volatility from "./Volatility/Volatility";
import { useEffect, useRef, useState } from "react";
import {
  selectorEnabledModeling,
  selectorPositionModeling,
  selectorVolatilityAdjustment,
  selectorVolatilityPointPercent,
} from "redux/Analysis/Analysis.selectors";
import { useDispatch, useSelector } from "react-redux";
import {
  setPositionModeling,
  setVolatilityAdjustment,
  setVolatilityPointPercent,
  toggleEnableModeling,
  ModelQty,
  OptionChains as OptionChainsInterface,
} from "redux/Analysis";

interface ModelingProps {
  optionChains: OptionChainsInterface;
  setOptionChains: (optionChains: OptionChainsInterface) => void;
  modelQty: ModelQty;
  setModelQty: (modelQty: ModelQty) => void;
}

export const Modeling = ({ optionChains, setOptionChains, modelQty, setModelQty }: ModelingProps) => {
  // const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  const enableModeling = useSelector(selectorEnabledModeling);
  const volatilityAdjustment = useSelector(selectorVolatilityAdjustment);
  const volatilityPointPercent = useSelector(selectorVolatilityPointPercent);
  const positionModeling = useSelector(selectorPositionModeling);

  // useEffect(() => {
  //   if (ref.current != null) setHeight(ref.current.clientHeight);
  // }, [setHeight]);

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Box>
          <Paper elevation={1} sx={{ p: 2 }} ref={ref}>
            <Grid2 container spacing={2}>
              <Grid2 flex={1}>
                <FormControlLabel
                  className="noDrag"
                  control={<Checkbox />}
                  label="Volatility Modeling"
                  //   value={enableModeling}
                  checked={enableModeling}
                  onChange={(_, checked) => {
                    dispatch(toggleEnableModeling());
                    // if (!checked) dispatch(setModelQty({}));
                  }}
                />
              </Grid2>
              <Grid2 size={4}>
                <Volatility
                  className="noDrag"
                  onChange={(_: any, value: number) => {
                    dispatch(setVolatilityAdjustment(value));
                  }}
                  value={volatilityAdjustment}
                />
              </Grid2>
              <Grid2 size={2}>
                <FormControl variant="standard" fullWidth className="noDrag">
                  <Select
                    id="volatility-point-percent"
                    value={volatilityPointPercent}
                    onChange={(event) => {
                      const value: "Point" | "Percent" =
                        event.target.value == "Point" ? "Point" : "Percent";
                      dispatch(setVolatilityPointPercent(value));
                    }}
                  >
                    <MenuItem value={"Point"}>Point</MenuItem>
                    <MenuItem value={"Percent"}>Percent</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </Paper>
        </Box>
      </Grid2>
      <Grid2 size={12}>
        <Box>
          <Paper elevation={1} sx={{ p: 2 }}>
            <Grid2 container spacing={0}>
              <Grid2 size={12}>
                <FormControlLabel
                  className="noDrag"
                  control={<Checkbox />}
                  label="Position Modeling"
                  checked={positionModeling}
                  onChange={(_, checked) => {
                    dispatch(setPositionModeling(checked));
                  }}
                />
              </Grid2>
              <Grid2 size={12}>
                <OptionChains optionChains={optionChains} setOptionChains={setOptionChains} modelQty={modelQty} setModelQty={setModelQty} />
              </Grid2>
            </Grid2>
          </Paper>
        </Box>
      </Grid2>
    </Grid2>
  );
};
