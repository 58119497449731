import { createSelector } from "reselect";
import { StoreState } from "redux/store.types";
import { selectorSelectionModel } from "redux/UI";
import { useSelector } from "react-redux";
import { JournalEntry } from "journal-lib";
import { getNowDateString } from "utils";

// const namespacedStore = (state: NamespacedStoreState) => state[moduleNamespace];
const storeState = (state: StoreState) => state;

export const selectorJournalEntriesStore = createSelector(
  storeState,
  (state) => state?.journalEntry
);

export const selectorCanCloseRoll = createSelector(
  [selectorJournalEntriesStore, selectorSelectionModel],
  (entryStore, selectionModel) => {
    if (selectionModel && selectionModel.length > 0) {
      const entry = entryStore?.JournalEntries?.find(
        (entry) => entry.entryId === selectionModel[0]
      );
      if (entry) return entry.status === "Open";
    }
    return false;
  }
);

export const selectorJournalEntries = createSelector(
  [
    selectorJournalEntriesStore,
    // selectorFilterModel,
    // selectorFilterBySymbol,
    // selectorFilterByAccount,
  ],
  (state /*, filterModel, filterSymbol, filterAccount*/) => {
    // const filter = filterModel;
    // switch (filter) {
    //   case "today":
    //     return state?.JournalEntries?.filter(
    //       (entry) =>
    //         getDateString(new Date(entry.initialDate)) === getNowDateString() ||
    //         getDateString(new Date(entry.endDate)) === getNowDateString()
    //     );
    //   case "open":
    //     return state?.JournalEntries?.filter(
    //       (entry) => entry.status === "Open"
    //     );
    //   case "symbol":
    //     return state?.JournalEntries?.filter(
    //       (entry) => entry.symbol.toUpperCase() === filterSymbol?.toUpperCase()
    //     );
    //   case "account":
    //     return state?.JournalEntries?.filter(
    //       (entry) => entry.account === filterAccount?.value
    //     );
    //   default:
    return state?.JournalEntries;
    // }
  }
);

const selectorSelectedEntry = createSelector(
  [selectorJournalEntriesStore, selectorSelectionModel],
  (entryStore, selectionModel) => {
    if (selectionModel && selectionModel.length > 0) {
      const entry = entryStore?.JournalEntries?.find(
        (entry) => entry.entryId === selectionModel[0]
      );
      if (entry) return entry;
    }
    const nowDate = new Date();
    const emptyEntry: JournalEntry = {
      createAt: nowDate,//getNowDateString(),
      status: "Closed",
      mostRecentExp: nowDate,//getNowDateString(),
      initialDate: nowDate,//getNowDateString(),
      endDate: nowDate,//getNowDateString(),
      symbol: "",
      position: {
        strikeDesc: "",
        name: "BWBHCallButterfly",
        cumPrice: 0,
        legs: [],
        activities: [],
      },
      tags: [],
    };
    return emptyEntry;
  }
);

export const selectorOpenSymbol = createSelector(
  [selectorJournalEntries],
  (journalEntries) =>
    journalEntries
      .filter((entry) => entry.status === "Open")
      .map((entry) => entry.symbol)
      .filter((value, index, self) => self.indexOf(value) === index)
);

export const selectorEarningDates = createSelector(
  [selectorJournalEntriesStore],
  (entryStore) => entryStore.EarningDates
);

export const SelectSelectedEntry = () => useSelector(selectorSelectedEntry);
