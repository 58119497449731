import { OptionChainsTable } from "./OptionChainTable";
import { useDispatch, useSelector } from "react-redux";
import { selectorOptionChains } from "redux/Analysis/Analysis.selectors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Grid2 as Grid, SelectChangeEvent } from "@mui/material";
import { updateOptionChains, OptionChains as OptionChainsInterface, ModelQty, setModelQty } from "redux/Analysis";
import {
  calculateDTE,
  deepSet,
  toUTCString,
} from "utils";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { ActivityLeg } from "journal-lib";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

interface OptionChainsProps {
  optionChains: OptionChainsInterface;
  setOptionChains: (optionChains: OptionChainsInterface) => void;
  modelQty: ModelQty;
  setModelQty: (modelQty: ModelQty) => void;
}

export const OptionChains = ({ optionChains, setOptionChains, modelQty, setModelQty }: OptionChainsProps) => {
  const dispatch = useDispatch();
  // const data = useSelector(selectorOptionChains);

  //yyyy-MM-dd
  const updateQuotes = async (expDateStr: string) => {

    try {
      let newOptionChains = { ...optionChains };

      const needGreeks: string[] = [];
      if (expDateStr in newOptionChains) {
        for (const strike in newOptionChains[expDateStr]) {
          if (newOptionChains[expDateStr][strike].put.greeks == undefined)
            needGreeks.push(expDateStr);
          break;
        }
      }

      // console.log("needGreeks", needGreeks);
      for (const item of needGreeks) {
        const data: OptionChainsInterface[] = await API.post(
          "optionOrder",
          "/marketData/quotes",
          {
            body: {
              symbols: Object.values(newOptionChains[item])
                .map((item: any) =>
                  Object.values(item).filter((item: any) => item.type == "put")
                )
                .flat(),
            },
          }
        );
        // console.log("data", data);
        // const optionChains2: any = { ...optionChains };
        data.map((putItem: any) => {
          deepSet(newOptionChains, [item, putItem.strike, "put"], { ...putItem });
        });
        const data2: OptionChainsInterface[] = await API.post(
          "optionOrder",
          "/marketData/quotes",
          {
            body: {
              symbols: Object.values(optionChains[item])
                .map((item: any) =>
                  Object.values(item).filter((item: any) => item.type == "call")
                )
                .flat(),
            },
          }
        );
        // console.log("data", data);
        // const optionChains2: any = { ...optionChains };
        data2.map((callItem: any) => {
          deepSet(newOptionChains, [item, callItem.strike, "call"], { ...callItem });
        });
      }

      setOptionChains(newOptionChains);
    } catch (e) {
      console.log("error updateQuotes", e);
    }
  };

  return (
    <Box className="noDrag">
      <Grid container spacing={3} padding={0}>
        <Grid size={12}>
          {Object.keys(optionChains).map((expDate) => {
            // const dte = Math.round(
            //   DateTime.fromFormat(expDate, "yyyy-MM-dd").diffNow("days").days
            // );
            const expDTE = `${expDate} (${calculateDTE(expDate)} Days)`;
            const expDateStr = toUTCString(expDate, "yyyy-MM-dd");
            return (
              <Accordion
                key={`${expDate}-accordion`}
                elevation={4}
                slotProps={{ transition: { unmountOnExit: true } }}
                onChange={async (event, expanded) => {
                  if (expanded) {
                    //dispatch(updateOptionChains([expDate]));
                    // setSelected(value);
                    await updateQuotes(expDateStr);
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel1-content"
                  id="expDate-header"
                >
                  <Typography>{expDTE}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <OptionChainsTable strikes={optionChains[expDateStr]} exp={expDateStr} modelQty={modelQty} setModelQty={setModelQty} />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
