import { createSelector } from "reselect";
import { StoreState } from "redux/store.types";

// const namespacedStore = (state: NamespacedStoreState) => state[moduleNamespace];
const storeState = (state: StoreState) => state;

export const selectorSmartOptionsAIStore = createSelector(
    storeState,
    (state) => state?.smartOptionAI
);

export const selectorActivityTemplates = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.activityTemplates;
    }
);

export const selectorActivities = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.activities;
    }
);

export const selectorLegRuleFields = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.legRuleFields;
    }
);

export const selectorTotalRuleFields = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.totalRuleFields;
    }
);

export const selectorTradeFormCache = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.tradeFormCache;
    }
);

export const selectorSymbolFilter = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.symbolFilter;
    }
);

export const selectorStatusFilter = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.statusFilter;
    }
);

export const selectorAccountFilter = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.accountFilter;
    }
);

export const selectorStartDateFilter = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.startDateFilter;
    }
);

export const selectorEndDateFilter = createSelector(
    [
        selectorSmartOptionsAIStore,
    ],
    (state) => {
        return state?.endDateFilter;
    }
);
