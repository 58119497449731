import { AnyAction, createReducer } from "@reduxjs/toolkit";
import { SmartOptionsAIStoreState } from "./SmartOptionsAI.types";
import { ActivityTemplate } from "journal-lib";
import { addActivityTemplateResolved, deleteActivityTemplateResolved, editActivityTemplateResolved, getActivitiesResolved, getActivityTemplates, getActivityTemplatesResolved, getLegRuleFields, getLegRuleFieldsResolved, getTotalRuleFieldsResolved, resetActivityTemplates, setAccountFilter, setEndDateFilter, setStartDateFilter, setStatusFilter, setSymbolFilter, setTradeFormCache } from "./SmartOptionsAI.actions";

const initialState: SmartOptionsAIStoreState = {
    activityTemplates: [],
    // totalCount: 0,
    // loading: false,
    legRuleFields: [],
    totalRuleFields: [],
    activities: [],

    tradeFormCache: {
        accountId: '',
        symbol: '',
    },

    statusFilter: '',
    symbolFilter: '',
    accountFilter: '',
    startDateFilter: '',
    endDateFilter: ''
};

interface SmartOptionsAIReducer {
    [x: string]: (
        state: SmartOptionsAIStoreState,
        action: AnyAction
    ) => SmartOptionsAIStoreState | undefined;
}

const removeTemplate = (templates: ActivityTemplate[], id: string) => {
    return templates.filter(
        (template) => template.id && id != template.id
    );
};

const reducer: SmartOptionsAIReducer = {
    [resetActivityTemplates.toString()]: () => {
        return { ...initialState };
    },
    // [getActivityTemplates.toString()]: (state) => ({
    //     ...state,
    //     loading: true,
    // }),
    [getActivityTemplatesResolved.toString()]: (state, action) => ({
        ...state,
        activityTemplates: action.payload?.items,
        // loading: false,
        // totalCount: action.payload?.totalCount,
    }),
    [getActivitiesResolved.toString()]: (state, action) => ({
        ...state,
        activities: action.payload?.items,
        // loading: false,
        // totalCount: action.payload?.totalCount,
    }),
    [getLegRuleFieldsResolved.toString()]: (state, action) => ({
        ...state,
        legRuleFields: action.payload,
    }),
    [getTotalRuleFieldsResolved.toString()]: (state, action) => ({
        ...state,
        totalRuleFields: action.payload,
    }),
    [addActivityTemplateResolved.toString()]: (state, action) => {
        return {
            ...state,
            activityTemplates:
                action.payload && action.payload.id.length > 0
                    ? [...state.activityTemplates, action.payload]
                    : state.activityTemplates,
        };
    },
    [deleteActivityTemplateResolved.toString()]: (state, action) => {
        return {
            ...state,
            activityTemplates: removeTemplate(
                state.activityTemplates,
                action.payload.id
            ),
        };
    },
    [editActivityTemplateResolved.toString()]: (state, action) => ({
        ...state,
        activityTemplates: state.activityTemplates.map((template) =>
            template.id === action.payload.id
                ? action.payload
                : template
        ),
    }),
    [setTradeFormCache.toString()]: (state, action) => ({
        ...state,
        tradeFormCache: action.payload
    }),
    [setSymbolFilter.toString()]: (state, action) => ({
        ...state,
        symbolFilter: action.payload,
    }),
    [setStatusFilter.toString()]: (state, action) => ({
        ...state,
        statusFilter: action.payload
    }),
    [setAccountFilter.toString()]: (state, action) => ({
        ...state,
        accountFilter: action.payload
    }),
    [setStartDateFilter.toString()]: (state, action) => ({
        ...state,
        startDateFilter: action.payload
    }),
    [setEndDateFilter.toString()]: (state, action) => ({
        ...state,
        endDateFilter: action.payload
    }),
};

export default createReducer(initialState, reducer);

