import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { selectorAddLegsDrawer, toggleAddLegsDrawer } from "redux/UI";
import { useDispatch, useSelector } from "react-redux";
import LoaderButton from "components/LoaderButton";
import { Activity, GetCommonDiv, RollPostBody } from "journal-lib";
import { CloseEntry } from "components/CloseEntry";
import { AddEntry } from "components/AddEntry";
import { useFormFields } from "libs/hooksLib";
import {
  getNowDateString,
  stringToUTC,
  usePhoneScreenMedia,
  useXsDownMedia,
} from "utils";
import { GridRowId } from "@mui/x-data-grid";
import { rollEntry, SelectSelectedEntry } from "redux/JournalEntries";
import { Autocomplete } from "@mui/material";
import clsx from "clsx";
import {
  clearEntryPreviewPLChart,
  getEntryPreviewPLChart,
  getExpDate,
} from "redux/Stock/Stock.actions";
import { useAddLegsStyles } from "./AddLegs.styles";
import {
  formValidation,
  getFieldErrorMsg,
  validateField,
} from "utils/formValidation";
import { PLChart } from "components/PLChart/PLChart.components";

// function getSteps() {
//   return ["Close existing position", "Open new position"];
// }

export const AddLegs = () => {
  const dispatch = useDispatch();
  const classes = useAddLegsStyles();
  // const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  // const [skipped, setSkipped] = useState(new Set());
  const [postToFeed, setPostToFeed] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [showPL, setShowPL] = useState(false);

  const isXs = useXsDownMedia();
  const isPhoneScreen = usePhoneScreenMedia();
  const selectedEntry = SelectSelectedEntry();
  const isOpen = useSelector(selectorAddLegsDrawer);

  const addEntryRef = useRef<any>();

  const [debitCredit_closeEntry, setDebitCredit_closeEntry] = useState<{
    value: string;
    label: string;
  }>({ value: "credit", label: "Credit" });
  const initEntry_CloseEntry: Activity = {
    type: "closing",
    desc: "initial",
    id: "",
    date: new Date(),//getNowDateString(),
    commission: 0,
    price: 0,
    legs: [],
    note: "",
    externalId: "",
  };
  const [entry_closeEntry, setEntry_closeEntry] =
    useState<Activity>(initEntry_CloseEntry);
  const [selectionModel_closeEntry, setSelectionModel_closeEntry] = useState<
    GridRowId[]
  >([]);
  // const accounts = useSelector(selectorAccountStore);
  // const accountArray: { value: string; label: string }[] = Object.keys(
  //   accounts
  // ).map((accountId) => {
  //   return {
  //     value: accountId,
  //     label: accounts[accountId].description,
  //   };
  // });
  const initRollFields = {
    rollDate: getNowDateString(),
    rollPrice: 0,
    debitCredit: { value: "debit", label: "Debit" },
  };
  const [rollFields, setRollFields] = useFormFields(initRollFields);
  const initFields_addEntry = {
    symbol: selectedEntry.symbol,
    name: { value: "ShortPut", label: "Short Put" },
    entryDate: getNowDateString(),
    price: 0,
    debitCredit: { label: "Credit", value: "credit" },
    size: 1,
    // account: accountArray.length > 0 ? accountArray[0].value : "",
    account: { value: selectedEntry?.account },
    expDate: { label: "", value: "" },
    expDateInput: "",
    note: "",
    strikes: "",

    type1: "sto",
    strike1: 0,
    expDate1: { label: "", value: "" },
    expDate1Input: "",
    qty1: 0,
    type2: "sto",
    strike2: 0,
    expDate2: { label: "", value: "" },
    expDate2Input: "",
    qty2: 0,
    type3: "sto",
    strike3: 0,
    expDate3: { label: "", value: "" },
    expDate3Input: "",
    qty3: 0,
    type4: "sto",
    strike4: 0,
    expDate4: { label: "", value: "" },
    expDate4Input: "",
    qty4: 0,
  };
  const [fields_addEntry, handleFieldChange_addEntry] =
    useFormFields(initFields_addEntry);

  const validateForm: formValidation = {
    price: {
      isValid: () => fields_addEntry["price"] >= 0,
      msg: "Price must be positive",
    },
    debitCredit: {
      isValid: () =>
        fields_addEntry["debitCredit"].value === "debit" ||
        fields_addEntry["debitCredit"].value === "credit",
      msg: "Must be either debit or credit",
    },

    all: {
      isValid: () => {
        for (const key in validateForm) {
          if (key != "all" && !validateForm[key].isValid()) return false;
        }
        return true;
      },
      msg: "",
    },
  };
  useEffect(() => {
    const v = validateForm.all.isValid() && addEntryRef.current?.validateAll();
    if (v != isValid) setIsValid(v);
  }, [fields_addEntry, isValid, validateForm.all]);

  const previewPL = () => {
    if (isValid) {
      dispatch(getEntryPreviewPLChart(getRollBody()));
      setShowPL(true);
    }
  };

  const addBody = () => ({
    ...fields_addEntry,
    // account: fields_addEntry.account.value,
    name: fields_addEntry.name.value,
    // type1: fields_addEntry.type1.value,
    // type2: fields_addEntry.type2.value,
    // type3: fields_addEntry.type3.value,
    // type4: fields_addEntry.type4.value,
    putCall1: fields_addEntry.putCall1?.value,
    putCall2: fields_addEntry.putCall2?.value,
    putCall3: fields_addEntry.putCall3?.value,
    putCall4: fields_addEntry.putCall4?.value,
    entryDate: stringToUTC(fields_addEntry.entryDate),
    expDate:
      fields_addEntry.expDate.value.trim().length > 0
        ? stringToUTC(fields_addEntry.expDate.value)
        : stringToUTC(fields_addEntry["expDateInput"]),
    expDate1:
      fields_addEntry.expDate1.value.trim().length > 0
        ? stringToUTC(fields_addEntry.expDate1.value)
        : stringToUTC(fields_addEntry["expDate1Input"]),
    expDate2:
      fields_addEntry.expDate2.value.trim().length > 0
        ? stringToUTC(fields_addEntry.expDate2.value)
        : stringToUTC(fields_addEntry["expDate2Input"]),
    expDate3:
      fields_addEntry.expDate3.value.trim().length > 0
        ? stringToUTC(fields_addEntry.expDate3.value)
        : stringToUTC(fields_addEntry["expDate3Input"]),
    expDate4:
      fields_addEntry.expDate4.value.trim().length > 0
        ? stringToUTC(fields_addEntry.expDate4.value)
        : stringToUTC(fields_addEntry["expDate4Input"]),
  });

  const getStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <CloseEntry
            key="closeEntry"
            isDialog={false}
            entry_parent={entry_closeEntry}
            setEntry_parent={setEntry_closeEntry}
            debitCredit_parent={debitCredit_closeEntry}
            setDebitCredit_parent={setDebitCredit_closeEntry}
            selectionModel_parent={selectionModel_closeEntry}
            setSelectionModel_parent={setSelectionModel_closeEntry}
          />
        );
      case 1:
        return (
          <AddEntry
            key="addEntry"
            isDialog={false}
            fields_parent={fields_addEntry}
            handleFieldChange_parent={handleFieldChange_addEntry}
            totalPrice_parent={totalPrice}
            setTotalPrice_parent={setTotalPrice}
            validateMode={showError}
            ref={addEntryRef}
          />
        );
      case 2:
        return "This is the bit I really care about!";
      default:
        return "Unknown step";
    }
  };

  // const isStepOptional = (step: any) => {
  //   return step === 0;
  //   // return false;
  // };

  // const isStepSkipped = (step: any) => {
  //   return skipped.has(step);
  // };

  // const handleNext = () => {
  //   let newSkipped = skipped;
  //   if (isStepSkipped(activeStep)) {
  //     newSkipped = new Set(newSkipped.values());
  //     newSkipped.delete(activeStep);
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped(newSkipped);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  const onClosing = () => {
    dispatch(toggleAddLegsDrawer(false));
  };

  const reset = () => {
    setIsLoading(false);
    setShowError(false);

    dispatch(clearEntryPreviewPLChart());
    setShowPL(false);

    setSelectionModel_closeEntry([]);
    setEntry_closeEntry(initEntry_CloseEntry);
    setDebitCredit_closeEntry({ value: "credit", label: "Credit" });

    setRollFields({ target: { id: "rollDate", value: getNowDateString() } });
    setRollFields({ target: { id: "rollPrice", value: 0 } });
    setRollFields({
      target: {
        id: "debitCredit",
        value: { label: "Credit", value: "credit" },
      },
    });

    handleFieldChange_addEntry({ target: { id: "symbol", value: "" } });
    handleFieldChange_addEntry({
      target: { id: "name", value: { value: "ShortPut", label: "Short Put" } },
    });
    handleFieldChange_addEntry({
      target: { id: "entryDate", value: getNowDateString() },
    });
    handleFieldChange_addEntry({ target: { id: "price", value: 0 } });
    setTotalPrice(0);
    handleFieldChange_addEntry({
      target: {
        id: "debitCredit",
        value: { label: "Credit", value: "credit" },
      },
    });
    handleFieldChange_addEntry({ target: { id: "size", value: 1 } });
    // handleFieldChange_addEntry({
    //   target: {
    //     id: "account",
    //     // value: accountArray.length > 0 ? accountArray[0].value : "",
    //     vale: selectedEntry.account,
    //   },
    // });
    handleFieldChange_addEntry({
      target: { id: "expDate", value: { label: "", value: "" } },
    });
    handleFieldChange_addEntry({ target: { id: "note", value: "" } });
    handleFieldChange_addEntry({ target: { id: "strikes", value: "" } });
    handleFieldChange_addEntry({ target: { id: "type1", value: "sto" } });
    handleFieldChange_addEntry({ target: { id: "strike1", value: "" } });
    handleFieldChange_addEntry({
      target: { id: "expDate1", value: { label: "", value: "" } },
    });
    handleFieldChange_addEntry({ target: { id: "qty1", value: 0 } });
    handleFieldChange_addEntry({ target: { id: "type2", value: "sto" } });
    handleFieldChange_addEntry({ target: { id: "strike2", value: "" } });
    handleFieldChange_addEntry({
      target: { id: "expDate2", value: { label: "", value: "" } },
    });
    handleFieldChange_addEntry({ target: { id: "qty2", value: 0 } });
    handleFieldChange_addEntry({ target: { id: "type3", value: "sto" } });
    handleFieldChange_addEntry({ target: { id: "strike3", value: "" } });
    handleFieldChange_addEntry({
      target: { id: "expDate3", value: { label: "", value: "" } },
    });
    handleFieldChange_addEntry({ target: { id: "qty3", value: 0 } });
    handleFieldChange_addEntry({ target: { id: "type4", value: "sto" } });
    handleFieldChange_addEntry({ target: { id: "strike4", value: "" } });
    handleFieldChange_addEntry({
      target: { id: "expDate4", value: { label: "", value: "" } },
    });
    handleFieldChange_addEntry({ target: { id: "qty4", value: 0 } });
    // setActiveStep(0);
  };

  const isFieldValid = (sField: string) => validateField(sField, validateForm);
  const fieldErrorMsg = (sField: string) =>
    getFieldErrorMsg(sField, validateForm);

  const getRollBody = () => {
    const closeActivity = {
      ...entry_closeEntry,
      legs: selectionModel_closeEntry.map(
        (index) => entry_closeEntry.legs[Number(index) - 1]
      ),
    };

    const body = addBody();

    let price = totalPrice;
    if (
      fields_addEntry.name.value === "ShortStock" ||
      fields_addEntry.name.value === "LongStock"
    )
      price = totalPrice / 100;

    const rollBody: RollPostBody = {
      entryId: selectedEntry.entryId ?? "",
      rollPrice: rollFields.debitCredit.value === "credit" ? price : price * -1,
      rollDate: new Date(stringToUTC(rollFields["rollDate"])),
      activity: closeActivity,
      addEntryPostBody: body,
      postToFeed: postToFeed,
      note: body.note,
    };
    return rollBody;
  };

  async function handleSubmit(event: any) {
    setIsLoading(true);
    event.preventDefault();

    if (isValid && selectedEntry.entryId) {
      const rollBody = getRollBody();

      dispatch(rollEntry(rollBody));
      //   try {
      //     dispatch(
      //       addEntry({
      //         ...fields,
      //         price:
      //           fields["debitCredit"] === "credit"
      //             ? fields["price"] * -1
      //             : fields["price"],
      //       })
      //     );
      //   } catch (e) {
      //     setIsLoading(false);
      //   }
    } else {
      setShowError(true);
      setIsLoading(false);
    }
  }

  const getTotalPriceMultiplier = (e: any) => {
    const qtyArray = (
      e.target.id === "price"
        ? [
            fields_addEntry.qty1,
            fields_addEntry.qty2,
            fields_addEntry.qty3,
            fields_addEntry.qty4,
          ]
        : [
            e.target.id === "qty1" ? e.target.value : fields_addEntry.qty1,
            e.target.id === "qty2" ? e.target.value : fields_addEntry.qty2,
            e.target.id === "qty3" ? e.target.value : fields_addEntry.qty3,
            e.target.id === "qty4" ? e.target.value : fields_addEntry.qty4,
          ]
    ).filter((qty) => qty !== 0 && qty !== "");
    if (qtyArray.length > 1) {
      return Math.abs(GetCommonDiv(qtyArray));
    } else if (qtyArray.length === 1) {
      return Math.abs(+qtyArray[0]);
    } else {
      return 0;
    }
  };

  return (
    <Dialog
      open={isOpen}
      fullScreen={isPhoneScreen}
      onClose={onClosing}
      fullWidth
      maxWidth="md"
      className={classes.root}
      TransitionProps={{
        onEntering: () => {},

        onEnter: () => {
          if (selectedEntry && selectedEntry.symbol) {
            dispatch(getExpDate(selectedEntry.symbol));
          }
          setEntry_closeEntry({
            type: "closing",
            desc: "initial",
            id: "",
            date: new Date(),//getNowDateString(),
            commission: 0,
            price: 0,
            legs: [],
            note: "",
            externalId: "",
          });
          reset();
        },
      }}
    >
      <form
        className="RollingForm"
        onSubmit={handleSubmit}
        style={{ width: "100%" }}
      >
        <DialogTitle id="add-entry-dialog-title">Add</DialogTitle>

        <DialogContent
          style={{ overflow: "hidden" }}
          className={clsx({ [classes.noPadding]: isXs })}
        >
          <Grid container spacing={3} style={{ padding: "0 20px" }}>
            <Grid
              container
              spacing={3}
              item
              xs={12}
              style={{ padding: "12px 12px 12px 32px" }}
            >
              <Grid item xs={isXs ? 12 : 5}>
                <TextField
                  fullWidth
                  id="rollDate"
                  label="Add Date"
                  type="date"
                  value={rollFields["rollDate"]}
                  onChange={setRollFields}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={isXs ? 6 : 3}>
                <Autocomplete
                  value={rollFields["debitCredit"]}
                  onChange={(event, newValue: any) => {
                    if (newValue) {
                      setRollFields(
                        {
                          target: { id: "debitCredit", value: newValue },
                        },
                        "debitCredit"
                      );
                    } else {
                      setRollFields(
                        { target: { id: "debitCredit", value: "" } },
                        "debitCredit"
                      );
                    }
                  }}
                  // autoSelect
                  id="debitCredit"
                  options={[
                    { label: "Debit", value: "debit" },
                    { label: "Credit", value: "credit" },
                  ]}
                  getOptionLabel={(option: any) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Debit/Credit"
                      error={showError && !isFieldValid("debitCredit")}
                      helperText={showError && fieldErrorMsg("debitCredit")}
                    />
                  )}
                />
                {/* <FormControl fullWidth>
                  <InputLabel id="debitCredit-label">Debit/Credit</InputLabel>
                  <Select
                    fullWidth
                    labelId="Debit/Credit"
                    id="debitCredit"
                    value={rollFields["debitCredit"]}
                    onChange={(e) => setRollFields(e, "debitCredit")}
                  >
                    <MenuItem value={"debit"}>Debit</MenuItem>
                    <MenuItem value={"credit"}>Credit</MenuItem>
                  </Select>
                </FormControl> */}
              </Grid>
              <Grid item xs={isXs ? 6 : 2}>
                <TextField
                  fullWidth
                  id="rollPrice"
                  label="Price"
                  type="number"
                  value={rollFields["rollPrice"]}
                  onChange={(e) => {
                    setRollFields(e);
                    handleFieldChange_addEntry({
                      target: { id: "price", value: e.currentTarget.value },
                    });
                    setTotalPrice(
                      Math.round(
                        +e.target.value *
                          (fields_addEntry.name.value === "Custom"
                            ? getTotalPriceMultiplier(e)
                            : fields_addEntry.size) *
                          100
                      ) / 100
                    );
                  }}
                  className={clsx({
                    [classes.debit]: rollFields.debitCredit.value === "debit",
                    [classes.credit]: rollFields.debitCredit.value === "credit",
                  })}
                  inputProps={{ inputMode: "decimal", step: "0.01" }}
                  error={showError && !isFieldValid("price")}
                  helperText={showError && fieldErrorMsg("price")}
                />
              </Grid>
              <Grid item xs={isXs ? 6 : 2}>
                <TextField
                  fullWidth
                  id="totalPrice"
                  label="Total Price"
                  type="number"
                  value={totalPrice}
                  className={clsx({
                    [classes.debit]: rollFields.debitCredit.value === "debit",
                    [classes.credit]: rollFields.debitCredit.value === "credit",
                  })}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12}>
              <div className={classes.root}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: any = {};
                    const labelProps: any = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            </Grid> */}
            <Grid item xs={12}>
              {getStepContent(1)}
            </Grid>

            {showPL ? (
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                style={{ height: "250px" }}
              >
                <PLChart isPreview={true} />
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>

        <DialogActions>
          <div style={{ display: "flex", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <Button onClick={previewPL} className={classes.button}>
                Preview P/L
              </Button>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <Box>
                  Post activity to news feed?
                  <Checkbox
                    value={postToFeed}
                    defaultChecked
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    onChange={(event, checked) => setPostToFeed(checked)}
                  />
                </Box>
                <LoaderButton
                  type="submit"
                  // bsSize="large"
                  isLoading={isLoading}
                  color="primary"
                  // disabled={!validateform.all()}
                >
                  Add Entry
                </LoaderButton>
                <Button color="secondary" onClick={onClosing}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};
