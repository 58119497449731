import { useRef, useState, useEffect, act } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    Box,
    Typography,
    Divider,
} from "@mui/material";
import LoaderButton from "components/LoaderButton";
import { selectorReviewOrderDrawer } from "redux/UI/UI.selectors";
import { toggleAddActivityDrawer, toggleReviewOrderDrawer } from "redux/UI/UI.actions";
import { calculateDTE, usePhoneScreenMedia } from "utils";
import _ from "lodash";
import { ActivityLeg, AdvancedActivity } from "journal-lib";
import { API } from "aws-amplify";
import { getAccountBalance, selectorAccountStore } from "redux/Account";
import { getActivities } from "redux/SmartOptionsAI";

interface ReviewOrderProps {
    activity: AdvancedActivity | null;
    setActivity: (activity: AdvancedActivity) => void;
    bReplaceOrder?: boolean;
}

const ReviewOrder = ({ activity, setActivity, bReplaceOrder }: ReviewOrderProps) => {

    const accounts = useSelector(selectorAccountStore);

    const dispatch = useDispatch();
    const isOpen = useSelector(selectorReviewOrderDrawer);
    const isPhoneScreen = usePhoneScreenMedia();
    const [isLoading, setIsLoading] = useState(false);

    const [accountId, setAccountId] = useState("");
    const [symbol, setSymbol] = useState("");
    const [underlyingSymbol, setUnderlyingSymbol] = useState("");
    const [multiplier, setMultiplier] = useState(0);
    const [legs, setLegs] = useState<ActivityLeg[]>([]);
    const [type, setType] = useState("");
    const [price, setPrice] = useState(0);
    const [priceEffect, setPriceEffect] = useState("");
    const [TIF, setTIF] = useState("");
    const [fees, setFees] = useState(0);
    const [bpChange, setBPChange] = useState(0);
    const [bpChangeEffect, setBPChangeEffect] = useState("");
    const [notes, setNotes] = useState("");

    const [minIncrement, setMinIncrement] = useState(0.01);

    const isMultipleOfMinIncrement = (price: number, minIncrement: number) => {
        return Number.isInteger(price / minIncrement);
    }

    const dryRunOrder = (activity: AdvancedActivity) => {
        reset();
        if (activity && activity.accountId && activity.order?.legs) {

            setIsLoading(true);

            setAccountId(activity.accountId);
            setSymbol(activity.symbol);
            setMultiplier(activity.multiplier ?? 100);
            setLegs(activity.legs);
            setType(activity.order["order-type"]);
            setPrice(activity.order.price);
            setPriceEffect(activity.order["price-effect"]);
            setTIF(activity.order["time-in-force"]);

            if (bReplaceOrder) {
                API.put("optionOrder", "/smartAI/reviewEditOrder", {
                    body: { activity: activity },
                }).then((res) => {
                    if (res && res.result) {
                        // console.log("dryRunResult", res);

                        const buyingPowerEffect = res.result["buying-power-effect"];
                        const fee = res.result["fee-calculation"];
                        const order = res.result["order"];

                        setMinIncrement(res.minIncrement);

                        if (order) {
                            setUnderlyingSymbol(order["underlying-symbol"]);
                            if (!isMultipleOfMinIncrement(Number(order.price), Number(res.minIncrement))) {
                                setNotes(`Price must be in increments of $${res.minIncrement}`);
                            }
                        }
                        if (fee) {
                            setFees(Number(fee["total-fees"]));
                        }
                        if (buyingPowerEffect) {

                            setBPChangeEffect(buyingPowerEffect["change-in-buying-power-effect"]);
                            setBPChange(Number(buyingPowerEffect["change-in-buying-power"]));

                            if (accounts && accounts[accountId]) {
                                if (buyingPowerEffect["change-in-buying-power-effect"] == "Debit" && Number(accounts[accountId]?.optionBuyingPower) <= Number(buyingPowerEffect["change-in-buying-power"])) {
                                    setNotes("Option buying power is not enough");
                                }
                            }
                        }



                    }

                })
                    .catch((error) => {
                        //Failed example: {result:null,minIncrement:0,errorCode:tif_futures_session_not_active,errorMsg:The Futures trading session is not currently active.}

                        // Log error details
                        // console.error("dryRunOrder API error:", error);

                        // Optional: Show user-friendly message or handle specific error codes
                        if (error.response) {
                            // console.error("Status code:", error.response.status);
                            if (error.response.data) {
                                const minIncrement = error.response.data.minIncrement;
                                const errorCode = error.response.data.errorCode;
                                const errorMsg = error.response.data.errorMsg;
                                setNotes(errorMsg);
                            }

                        } else if (error.message) {
                            console.error(error.message);
                            setNotes(error.message);
                        }
                    }).finally(() => {
                        setIsLoading(false); // End loading
                    });
            }
            else {
                API.post("optionOrder", "/smartAI/reviewOrder", {
                    body: { activity: activity },
                }).then((res) => {
                    if (res) {
                        // console.log("dryRunResult", res);

                        const buyingPowerEffect = res.result["buying-power-effect"];
                        const fee = res.result["fee-calculation"];
                        const order = res.result["order"];

                        setMinIncrement(res.minIncrement);

                        if (order) {
                            setUnderlyingSymbol(order["underlying-symbol"]);
                            if (!isMultipleOfMinIncrement(Number(order.price), Number(res.minIncrement))) {
                                setNotes(`Price must be in increments of $${res.minIncrement}`);
                            }
                        }
                        if (fee) {
                            setFees(Number(fee["total-fees"]));
                        }
                        if (buyingPowerEffect) {

                            setBPChangeEffect(buyingPowerEffect["change-in-buying-power-effect"]);
                            setBPChange(Number(buyingPowerEffect["change-in-buying-power"]));

                            if (accounts && accounts[accountId]) {
                                if (buyingPowerEffect["change-in-buying-power-effect"] == "Debit" && Number(accounts[accountId]?.optionBuyingPower) <= Number(buyingPowerEffect["change-in-buying-power"])) {
                                    setNotes("Option buying power is not enough");
                                }
                            }
                        }



                    }

                })
                    .catch((error) => {
                        //Failed example: {result:null,minIncrement:0,errorCode:tif_futures_session_not_active,errorMsg:The Futures trading session is not currently active.}

                        // Log error details
                        // console.error("dryRunOrder API error:", error);

                        // Optional: Show user-friendly message or handle specific error codes
                        if (error.response) {
                            // console.error("Status code:", error.response.status);
                            if (error.response.data) {
                                const minIncrement = error.response.data.minIncrement;
                                const errorCode = error.response.data.errorCode;
                                const errorMsg = error.response.data.errorMsg;
                                setNotes(errorMsg);
                            }

                        } else if (error.message) {
                            console.error(error.message);
                            setNotes(error.message);
                        }
                    }).finally(() => {
                        setIsLoading(false); // End loading
                    });
            }
        }
    };

    useEffect(() => {
        if (activity) {
            if (activity.accountId) {
                dispatch(getAccountBalance({ id: activity.accountId }));
            }
            dryRunOrder(activity);
        }
    }, [
        activity, bReplaceOrder
    ]);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (activity && activity.accountId && activity.order?.legs && accounts && accounts[accountId]) {

            //If buying power is not enough
            if (bpChangeEffect == "Debit" && Number(accounts[accountId]?.optionBuyingPower) <= bpChange) {
                return;
            }

            if (!isMultipleOfMinIncrement(Number(activity.order.price), minIncrement)) {
                setNotes(`Price must be in increments of $${minIncrement}`);
                return;
            }

            setIsLoading(true);

            if (bReplaceOrder) {
                API.put("optionOrder", "/smartAI/replaceOrder", {
                    body: { activity: activity },
                }).then((res) => {
                    if (res) {
                        // console.log("sendOrder", res);
                        dispatch(toggleReviewOrderDrawer(false));
                        dispatch(getActivities({}));
                    }

                })
                    .catch((error) => {
                        //Failed example: {result:null,minIncrement:0,errorCode:tif_futures_session_not_active,errorMsg:The Futures trading session is not currently active.}

                        // Log error details
                        // console.error("dryRunOrder API error:", error);

                        // Optional: Show user-friendly message or handle specific error codes
                        if (error.response) {
                            // console.error("Status code:", error.response.status);
                            if (error.response.data) {
                                const minIncrement = error.response.data.minIncrement;
                                const errorCode = error.response.data.errorCode;
                                const errorMsg = error.response.data.errorMsg;
                                setNotes(errorMsg);
                            }

                        } else if (error.message) {
                            console.error(error.message);
                            setNotes(error.message);
                        }
                    }).finally(() => {
                        setIsLoading(false); // End loading
                    });
            }
            else {
                API.post("optionOrder", "/smartAI/sendOrder", {
                    body: { activity: activity },
                }).then((res) => {
                    if (res) {
                        // console.log("sendOrder", res);
                        dispatch(toggleReviewOrderDrawer(false));
                        dispatch(getActivities({}));
                    }

                })
                    .catch((error) => {
                        //Failed example: {result:null,minIncrement:0,errorCode:tif_futures_session_not_active,errorMsg:The Futures trading session is not currently active.}

                        // Log error details
                        // console.error("dryRunOrder API error:", error);

                        // Optional: Show user-friendly message or handle specific error codes
                        if (error.response) {
                            // console.error("Status code:", error.response.status);
                            if (error.response.data) {
                                const minIncrement = error.response.data.minIncrement;
                                const errorCode = error.response.data.errorCode;
                                const errorMsg = error.response.data.errorMsg;
                                setNotes(errorMsg);
                            }

                        } else if (error.message) {
                            console.error(error.message);
                            setNotes(error.message);
                        }
                    }).finally(() => {
                        setIsLoading(false); // End loading
                    });
            }


        }
    };

    const onClosing = () => {
        dispatch(toggleReviewOrderDrawer(false));
    };
    const handleEditOrder = () => {
        if (activity) {
            setActivity(activity);
        }
        dispatch(toggleReviewOrderDrawer(false));
        dispatch(toggleAddActivityDrawer(true));
    };

    const reset = () => {
        setNotes("");
    };

    return (
        <Dialog
            key={activity?.id}
            open={isOpen}
            onClose={onClosing}
            fullWidth
            maxWidth="md"
            fullScreen={isPhoneScreen}
            TransitionProps={{
                onExited: reset,
            }}
        >
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                <DialogTitle>Confirm Order</DialogTitle>
                <DialogContent style={{ overflow: "hidden" }}>
                    <Grid container spacing={2} style={{ padding: "0 20px" }}>
                        {/* Account Info */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {/* Labels Row */}
                                <Grid item xs={2}>
                                    <Typography fontWeight="bold">Symbol:</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    <Typography fontWeight="bold">Account:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                    <Typography fontWeight="bold">Stock Buying Power:</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                    <Typography fontWeight="bold">Option Buying Power:</Typography>
                                </Grid>

                                {/* Values Row */}
                                <Grid item xs={2}>
                                    <Typography>{underlyingSymbol ?? symbol}</Typography>
                                </Grid>
                                <Grid item xs={4} style={{ textAlign: 'right' }}>
                                    <Typography>{accounts ? (accounts[accountId]?.description) : accountId}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                    <Typography>${(accounts ? Number(accounts[accountId]?.stockBuyingPower) : 0).toLocaleString()}</Typography>
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: 'right' }}>
                                    <Typography>${(accounts ? Number(accounts[accountId]?.optionBuyingPower) : 0).toLocaleString()}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Divider Below Capital Info */}
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>

                        {/* Legs */}
                        <Grid item xs={6}>
                            <Box display="flex" flexDirection="column" gap={1}>
                                {activity?.legs.map((leg, legIndex) => (
                                    <Box
                                        key={legIndex}
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        sx={{
                                            backgroundColor: leg.type === 'bto' ? '#e0f7fa' : '#ffebee',
                                            padding: '5px',
                                            borderRadius: '5px',
                                            marginBottom: '3px',
                                            fontFamily: 'monospace',
                                            fontWeight: 'bold',
                                            width: 'fit-content',
                                            minWidth: '150px',
                                            justifyContent: 'space-evenly',
                                            gap: '8px'
                                        }}
                                    >
                                        <Typography>{leg.leg?.qty}</Typography>
                                        <Typography>{new Date(leg.leg?.option?.expDate ?? '').toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</Typography>
                                        <Typography>{calculateDTE(leg.leg?.option.expDate)}d</Typography>
                                        <Typography>{leg.leg?.option.strike}</Typography>
                                        <Typography color={leg.type === 'bto' ? 'green' : 'red'}>{leg.type.toUpperCase()}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>

                        {/* RIGHT SIDE INFO */}
                        <Grid item xs={6}>
                            <Box display="flex" flexDirection="column" gap={1} mt={1}>
                                {[
                                    {
                                        label: 'Type:', value: (
                                            <>
                                                Limit @ {price}{' '}
                                                <span style={{ color: priceEffect === 'Credit' ? 'green' : 'red' }}>
                                                    {priceEffect === 'Credit' ? 'cr' : 'db'}
                                                </span>
                                            </>
                                        )
                                    },
                                    { label: 'TIF:', value: TIF },
                                    {
                                        label: 'Est. Trade Cost:',
                                        value: `${price} x ${multiplier} = `,
                                        valueExtra: <span style={{ color: priceEffect === 'Credit' ? 'green' : 'red' }}>${(price * multiplier).toLocaleString()}</span>
                                    },
                                    {
                                        label: 'Est. Fees:',
                                        value: `$${fees.toFixed(2)}`
                                    },
                                    {
                                        label: 'Est. BP Effect:',
                                        value: bpChangeEffect === 'Credit'
                                            ? 'Increased by '
                                            : bpChangeEffect === 'Debit'
                                                ? 'Reduced by '
                                                : '',
                                        valueExtra: (
                                            <span style={{ color: bpChangeEffect === 'Credit' ? 'green' : 'red', fontWeight: 'bold' }}>
                                                ${bpChange.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                            </span>
                                        )
                                    }

                                ].map(({ label, value, valueExtra }, idx) => (
                                    <Box key={idx} display="flex" justifyContent="space-between">
                                        <Typography variant="body2" fontWeight="bold" minWidth="150px">{label}</Typography>
                                        <Typography variant="body2">
                                            {value} {valueExtra || null}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Grid>



                        {/* Notes Section (Read-Only) */}
                        <Grid item xs={12}>
                            <Box
                                border="1px solid #ccc"
                                borderRadius="6px"
                                padding="12px"
                                bgcolor="#f9f9f9"
                                fontSize="0.9rem"
                                color="#333"
                            >
                                {notes || "No additional notes."}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>


                <DialogActions>
                    <Button color="secondary" onClick={onClosing}>
                        Cancel
                    </Button>
                    <Button color="secondary" onClick={handleEditOrder}>
                        Edit Order
                    </Button>
                    <LoaderButton type="submit" isLoading={isLoading} color="primary">
                        Send Order
                    </LoaderButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default ReviewOrder;
