import useWindowSize from "utils/hooks/windowSize";
import { useState } from "react";
import { TSeries } from "./TSeries";
import { Entries } from "./Entries";
import "./Settings.css";
import { useSelector } from "react-redux";
import { selectorJournalEntries } from "redux/JournalEntries";
import { selectorEntries } from "redux/Analysis/Analysis.selectors";
import { Tab, Tabs } from "@mui/material";
import { Modeling } from "./Modeling/Modeling";
import { updateOptionChains, OptionChains as OptionChainsInterface, ModelQty, setModelQty } from "redux/Analysis";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        <>{children}</>
        // </Box>
      )}
    </div>
  );
}

interface SettingsProps {
  optionChains: OptionChainsInterface;
  setOptionChains: (optionChains: OptionChainsInterface) => void;
  modelQty: ModelQty;
  setModelQty: (modelQty: ModelQty) => void;
}

export const Settings = ({ optionChains, setOptionChains, modelQty, setModelQty }: SettingsProps) => {
  const { height } = useWindowSize();

  const entries = useSelector(selectorJournalEntries);
  const entriesInGraph = useSelector(selectorEntries);

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };
  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div
      style={{
        width: "100%",
        height: height * 0.7,
      }}
    >
      <div className="content-wrapper" style={{ maxWidth: "700px" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Parameters" {...a11yProps(0)} className="noDrag" />
          <Tab label="Positions" {...a11yProps(1)} className="noDrag" />
          <Tab label="Model" {...a11yProps(2)} className="noDrag" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <TSeries />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Entries entries={entries} entriesInGraph={entriesInGraph} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Modeling optionChains={optionChains} setOptionChains={setOptionChains} modelQty={modelQty} setModelQty={setModelQty} />
        </TabPanel>
      </div>
    </div>
  );
};
