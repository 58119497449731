import { useDispatch } from "react-redux";
import { JournalEntry } from "journal-lib";
import { Grid2 as Grid, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { setEntries } from "redux/Analysis";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";

export const Entries = ({
  entries,
  entriesInGraph,
}: {
  entries: JournalEntry[];
  entriesInGraph: JournalEntry[];
}) => {
  const [selectionModel, setSelectionModel] = useState<any>([]);
  const dispatch = useDispatch();
  // const entries = useSelector(selectorJournalEntries);
  // const entriesInGraph = useSelector(selectorEntries);

  // const onRowSelectionChanged = () => {
  //   const selectedIndex = gridInstance?.getSelectedRowIndexes();
  //   const selectedEntries: JournalEntry[] = [];
  //   selectedIndex?.map((index) => selectedEntries.push(entries[index]));
  //   console.log(selectedIndex);
  //   console.log(
  //     "selectedEntries.length, entriesInGraph.length",
  //     selectedEntries.length,
  //     entriesInGraph.length
  //   );
  //   if (selectedEntries.length != entriesInGraph.length)
  //     dispatch(setEntries(selectedEntries));
  // };
  const getDesc = (props: JournalEntry) => (
    <Grid container spacing={0}>
      {props?.position?.legs?.map((leg) => {
        // console.log(leg);
        const bHasExpDate = leg.option?.expDate != undefined;
        const expDate = bHasExpDate
          ? DateTime.fromISO(new Date(leg.option?.expDate??"").toISOString() ?? "")
          : DateTime.utc();
        return (
          <Grid
            size={12}
            container
            spacing={0}
            style={{ height: "19px" }}
            key={`${props.entryId} ${leg.qty} ${expDate
              .toLocal()
              .toFormat("MMM dd")} ${leg.option?.strike}`}
          >
            <Grid size={3}>
              <Typography variant="caption">{leg.qty}</Typography>
            </Grid>
            <Grid size={3}>
              <Typography variant="caption">
                {bHasExpDate ? expDate.toLocal().toFormat("MMM dd") : ""}
              </Typography>
            </Grid>
            <Grid size={2}>
              <Typography variant="caption">
                {bHasExpDate
                  ? `${Math.round(expDate.diffNow("days").days)}d`
                  : ""}
              </Typography>
            </Grid>
            <Grid size={3}>
              <Typography variant="caption">{leg.option?.strike}</Typography>
            </Grid>
            <Grid size={1}>
              <Typography variant="caption">
                {leg.option?.type == "put"
                  ? "P"
                  : leg.option?.type == "call"
                  ? "C"
                  : ""}
              </Typography>
            </Grid>
            {/* <Grid item xs={1}>
              <Typography variant="caption" id={bIsBuy ? "cr" : "db"}>
                {sAction}
              </Typography>
            </Grid> */}
            {/* {`${leg.quantity} ${
            bHasExpDate
              ? `${expDate
                  .toLocal()
                  .toFormat("MMM dd")} ${Math.round(
                  expDate.diffNow("days").days
                )}`
              : ""
          } ${leg.symbolMeta?.strike} ${
            leg.symbolMeta?.optionType == "put"
              ? "P"
              : leg.symbolMeta?.optionType == "call"
              ? "C"
              : ""
          } 
          ${sAction}`} */}
          </Grid>
        );
      })}
    </Grid>
  );
  // console.log("entries", entries);

  useEffect(() => {
    if (entriesInGraph != null)
      setSelectionModel(entriesInGraph.map((entry) => entry.entryId));
  }, [entriesInGraph]);
  const rows = useMemo(() => {
    return entries.map((entry) => {
      return {
        ...entry,
        id: entry.entryId,
      };
    });
  }, [entries]);

  const columns: GridColDef[] = [
    // { field: "id" },
    {
      field: "symbol",
      description: "Symbol",
      headerName: "Symbol",
    },
    {
      field: "strategy",
      description: "Strategy",
      headerName: "Strategy",
      valueGetter: (_, row) =>
        row.position.customName ? row.position.customName : row.position.name,
    },
    {
      field: "description",
      description: "Description",
      headerName: "Description",
      width: 200,
      renderCell: (params) => getDesc(params.row),
    },
    {
      field: "note",
      description: "Notes",
      headerName: "Notes",
      width: 200,
      valueGetter: (_, row) => row.notes,
    },
  ];

  return (
    <div className="control-pane">
      <div className="control-section">
        <DataGrid
          className="noDrag"
          checkboxSelection
          autosizeOnMount={true}
          rows={rows}
          columns={columns}
          getRowHeight={() => "auto"}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(selectionModel) => {
            if (selectionModel.length == 0) {
              setSelectionModel([]);
              // setSelectionSymbol("");
            } else {
              setSelectionModel(selectionModel);
              console.log(selectionModel);
              const selectedEntries = entries.filter(
                (entry) => selectionModel.indexOf(entry.entryId ?? "") > -1
              );
              // console.log("selectedEntries", selectedEntries);
              // if (selectedEntries.length != entriesInGraph.length)
              dispatch(setEntries(selectedEntries));
            }
          }}
          isRowSelectable={(params) => {
            if (!entriesInGraph || entriesInGraph.length == 0) return false;

            const graphSymbol = entriesInGraph[0].symbol;
            if (params.row.symbol != graphSymbol) return false;
            else return true;
          }}
        />
        {/* <GridComponent
          delayUpdate={true}
          pageSettings={{ pageSize: 100 }}
          ref={(grid) => (gridInstance = grid)}
          dataSource={entries}
          allowPaging={false}
          // pageSettings={{ pageCount: 5 }}
          allowSorting={true}
          allowFiltering={true}
          filterSettings={filterSettings}
          selectionSettings={selectionsettings}
          rowSelected={onRowSelectionChanged}
          rowDeselected={onRowSelectionChanged}
          dataBound={() => {
            const preSelected: number[] = [];
            entries.map((entry, index) => {
              if (
                entriesInGraph.find(
                  (selected) => selected.entryId == entry.entryId
                )
              )
                preSelected.push(index);
            });
            // console.log("preselected", preSelected);
            gridInstance?.selectRows(preSelected);
          }}
        >
          <ColumnsDirective>
            <ColumnDirective type="checkbox" width="50"></ColumnDirective>
            <ColumnDirective
              field="symbol"
              headerText="Symbol"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              headerText="Strategy"
              width="100"
              textAlign="Center"
              template={strategyTemplate}
            ></ColumnDirective>
            <ColumnDirective
              headerText="Description"
              width="150"
              textAlign="Center"
              template={descriptionTemplate}
            ></ColumnDirective>
            <ColumnDirective
              field="notes"
              headerText="Note"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent> */}
      </div>
    </div>
  );
};
