import { Box, Grid2 as Grid, Paper, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ActivityDetailProps } from "./ActivityDetail.types";
import clsx from "clsx";
import { currency2DigitFormatter, getMonthDateString } from "utils";
import "./ActivityDetail.css";
import { StrategyEnum } from "journal-lib";
import moment from "moment";
import { forwardRef } from "react";
import { DateTime } from "luxon";

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: "15px 16px",
//     "& button": {
//       display: "none",
//     },
//     "&:hover button": {
//       display: "inline-flex",
//       position: "absolute",
//       top: "-2px",
//       right: "3px",
//       width: "25px",
//       height: "25px",
//       minHeight: "20px",
//     },
//   },
//   hidden: {
//     display: "none",
//   },
//   debit: {
//     color: "red",
//   },
//   credit: {
//     color: "green",
//   },
//   legGrid: {
//     paddingBottom: "3px",
//   },
// }));

const useStyles = makeStyles((theme) => ({
  debit: {
    color: "red",
  },
  credit: {
    color: "green",
  },
}));

export const ActivityDetail = forwardRef(
  ({ activity, menu, entry, ...otherProps }: ActivityDetailProps, ref) => {
    const classes = useStyles();
    const isXs = false; // useXsDownMedia();
    const symbol = entry?.symbol;

    return (
      <Box>
        <style
          scoped
          dangerouslySetInnerHTML={{
            __html: `
            @media (max-width: 600px) {
              .legDescLarge{
                display: none
              }
            }
            @media (min-width: 600px) {
              .legDescXs {
                display: none
              }
            }
          `,
          }}
        />
        {entry ? (
          <Grid container>
            <Typography
              variant="h6"
              style={{ fontSize: "1rem" }}
            >{`$${symbol} - Total ${entry.position.cumPrice > 0 ? "Credits" : "Debits"
              }: `}</Typography>

            <Typography
              variant="h6"
              style={{ fontSize: "1rem", paddingLeft: "10px" }}
              className={clsx({
                debit: entry.position.cumPrice < 0,
                credit: entry.position.cumPrice > 0,
              })}
            >
              {/* {`${currency2DigitFormatter.format(
            entry.position.name   entry.position.cumPrice
          )}`} */}

              {currency2DigitFormatter.format(
                (entry?.position
                  ?.name as unknown as keyof typeof StrategyEnum) ===
                  "LongStock" ||
                  (entry?.position
                    ?.name as unknown as keyof typeof StrategyEnum) ===
                  "ShortStock"
                  ? +entry?.position?.cumPrice * 100
                  : +entry?.position?.cumPrice
              )}
            </Typography>
          </Grid>
        ) : (
          <></>
        )}
        <Paper elevation={3} className="paper">
          <Grid container>
            <Grid
              size={12}
              container
              display={"flex"}
              justifyContent="flex-start"
              style={{ marginBottom: "10px" }}
            >
              <Grid
                size={{ xs: 8, sm: 10 }}
                className={clsx({ hidden: !isXs })}
              >
                {
                  new Date(activity.date ?? "").toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                }
                {" - "}
                {activity.type === "closing"
                  ? "Closing"
                  : activity.type === "roll"
                    ? "Roll"
                    : activity.type === "add"
                      ? "Add"
                      : activity.desc}
              </Grid>
              <Grid
                fontSize={12}
                // sm={1}
                size={"grow"}
                container
                justifyContent="space-between"
              >
                <Typography variant="h6" style={{ fontSize: "1rem" }}>
                  {entry ? `${moment(activity.date).format("M/D")} ` : ""}
                  {activity.type === "closing"
                    ? `Closing - ${activity.desc}`
                    : activity.type === "roll"
                      ? `Roll - ${activity.desc}`
                      : activity.type === "add"
                        ? `Add - ${activity.desc}`
                        : `Open - ${activity.desc}`}
                </Typography>
                <Typography
                  className={clsx({
                    debit: +(activity.price || 0) < 0,
                    credit: +(activity.price || 0) > 0,
                  })}
                >
                  {currency2DigitFormatter.format(
                    activity.desc === "Long Stock" ||
                      activity.desc === "Short Stock"
                      ? +(activity.price || 0) * 100
                      : +(activity.price || 0)
                  )}
                  {/* {(+activity.price).toFixed(2)} */}
                </Typography>
              </Grid>
              {/* <Grid size={2} sm={1} container justify="flex-end">
          <Typography
            className={clsx({
              [classes.debit]: runningTotal < 0,
              [classes.credit]: runningTotal > 0,
            })}
          >
            {runningTotal.toFixed(2)}
          </Typography>
        </Grid> */}
            </Grid>
            {activity.legs?.map((leg) => (
              <Grid
                container
                size={12}
                key={`${activity.id}-${leg.leg?.option.strike}-${leg.leg?.option.type}-${leg.leg?.option.expDate}`}
              >
                <Grid size={12} container className="legGrid legDescXs">
                  <Grid size={8} container justifyContent="flex-start">
                    {leg.leg?.option.type === "future" ||
                      leg.leg?.option.type === "stock"
                      ? `${leg.type.toUpperCase()} ${leg.leg?.option.type.toUpperCase()}`
                      : `${leg.type.toUpperCase()} ${leg.leg?.option.expDate
                        ? getMonthDateString(
                          new Date(leg.leg?.option.expDate)
                        )
                        : ""
                      } ${leg.leg?.option.strike
                      } ${leg.leg?.option.type.toUpperCase()}`}
                  </Grid>
                  <Grid size={1} container justifyContent="flex-end">
                    {leg.leg?.qty}
                  </Grid>
                </Grid>

                <Grid
                  size={12}
                  container
                  className={clsx("legGrid", "legDescLarge")}
                >
                  {/* <Grid size={1} container justify="center"></Grid> */}
                  <Grid size={1} container justifyContent="flex-end">
                    {Math.round((leg.leg?.qty ?? 0) * 100) / 100}
                    {leg.type === "btc"
                      ? " BTC"
                      : leg.type === "bto"
                        ? " BTO"
                        : leg.type === "sto"
                          ? " STO"
                          : leg.type === "stc"
                            ? " STC"
                            : ""}
                  </Grid>
                  <Grid size={2} container justifyContent="center">
                    {leg.leg?.option.expDate
                      ? `
                              ${DateTime.fromISO(
                                new Date(leg.leg?.option.expDate).toISOString()
                      ).toFormat("M/d/yy     ")}
                              `
                      : ""}
                  </Grid>
                  <Grid size={1} container justifyContent="flex-start">
                    {leg.leg?.option.expDate
                      ? `  ${Math.round(
                        DateTime.fromISO( new Date(leg.leg?.option.expDate).toISOString()).diff(
                          DateTime.fromISO(new Date(activity.date??"").toISOString() ?? ""),
                          "days"
                        ).days
                      )}d
                              `
                      : ""}
                  </Grid>
                  <Grid size={1} container justifyContent="center">
                    {leg.leg?.option.strike}
                  </Grid>
                  <Grid size={1} container justifyContent="center">
                    {leg.leg?.option.type === "call"
                      ? "Call"
                      : leg.leg?.option.type === "put"
                        ? "Put"
                        : leg.leg?.option.type === "stock"
                          ? "Stock"
                          : leg.leg?.option.type === "future"
                            ? "Future"
                            : ""}
                  </Grid>
                  <Grid size={1} container justifyContent="center">
                    {leg.leg?.option.delta
                      ? Math.round(
                        ((leg.leg?.option.delta * leg.leg?.qty) /
                          Math.abs(leg.leg?.qty)) *
                        100
                      ) + "Δ"
                      : ""}
                  </Grid>
                  <Grid
                    size={1}
                    container
                    justifyContent="flex-end"
                    className={
                      leg.leg?.price
                        ? leg.leg?.price >= 0
                          ? classes.credit
                          : leg.leg?.price < 0
                            ? classes.debit
                            : ""
                        : ""
                    }
                  >
                    <Tooltip title="Cost">
                      <Typography>
                        {Math.round((leg.leg?.price ?? 0) * 100) / 100}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  {/* <Grid
                    item
                    xs={1}
                    container
                    justify="flex-end"
                    className={
                      leg.leg?.marketPrice
                        ? leg.leg?.marketPrice >= 0
                          ? classes.credit
                          : leg.leg?.marketPrice < 0
                          ? classes.debit
                          : ""
                        : ""
                    }
                  >
                    <Tooltip title="Market Price">
                      <Typography>
                        {Math.round((leg.leg?.marketPrice ?? 0) * 100) / 100}
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    container
                    justify="flex-end"
                    className={
                      (leg.leg?.price ?? 0) + (leg.leg?.marketPrice ?? 0)
                        ? (leg.leg?.price ?? 0) + (leg.leg?.marketPrice ?? 0) >=
                          0
                          ? classes.credit
                          : (leg.leg?.price ?? 0) +
                              (leg.leg?.marketPrice ?? 0) <
                            0
                          ? classes.debit
                          : ""
                        : ""
                    }
                  >
                    <Tooltip title="Gain">
                      <Typography>
                        {Math.round(
                          ((leg.leg?.price ?? 0) +
                            (leg.leg?.marketPrice ?? 0)) *
                            100
                        ) / 100}
                      </Typography>
                    </Tooltip>
                  </Grid> */}
                  {/* <Grid size={2} container justify="center">
                    {leg.leg?.option.expDate
                      ? `${
                          new Date(leg.leg?.option.expDate).getMonth() + 1
                        }/${new Date(
                          leg.leg?.option.expDate
                        ).getDate()}/${new Date(leg.leg?.option.expDate)
                          .getFullYear()
                          .toString()
                          .substr(2, 2)}`
                      : ""}
                  </Grid>
                  <Grid size={1} container justify="center">
                    {leg.leg?.option.strike}
                  </Grid>
                  <Grid size={1} container></Grid>
                  <Grid size={2} container justify="center">
                    {leg.leg?.option.type === "call"
                      ? "Call"
                      : leg.leg?.option.type === "put"
                      ? "Put"
                      : leg.leg?.option.type === "stock"
                      ? "Stock"
                      : leg.leg?.option.type === "future"
                      ? "Future"
                      : ""}
                  </Grid>
                  <Grid size={1} container justify="flex-end">
                    {Math.round((leg.leg?.qty || 0) * 100) / 100}
                  </Grid> */}
                </Grid>
              </Grid>
            ))}
            {activity.note && activity.note.trim() !== "" ? (
              <Grid size={12} style={{ paddingTop: "10px" }}>
                {/* Note
              <br /> */}
                <Typography variant="caption">{`${activity.note}`}</Typography>
              </Grid>
            ) : null}
          </Grid>
          {menu ? menu : <></>}
        </Paper>
      </Box>
    );
  }
);
