import { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import ActivityTemplateList from "components/SmartOptionsAI/ActivityTemplate/ActivityTemplateList/ActivityTemplateList.component";
import ActivityList from "components/SmartOptionsAI/Activity/ActivityList/ActivityList.component";

interface TabItem {
  id: string;
  label: string;
  content: JSX.Element; // Change content type to JSX.Element to allow component rendering
}

const SmartOptionsAI = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: TabItem[] = [
    { id: "tabActivities", label: "Trade Activities", content: <ActivityList /> },
    { id: "tabActivityTemplates", label: "Option Templates", content: <ActivityTemplateList /> },
  ];

  return (
    <Grid container spacing={3}>
      <Grid xs={12} item container alignItems="center" style={{ paddingTop: "25px" }}>
        <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
          {/* Tab Navigation */}
          <Tabs
            value={activeTab}
            onChange={(_event: React.SyntheticEvent, newValue: number) => setActiveTab(newValue)}
            textColor="primary"
            indicatorColor="primary"
            sx={{
              "& .MuiTabs-indicator": { backgroundColor: "#345c85", height: 3 },
              "& .MuiTab-root": {
                textTransform: "none",
                fontWeight: 600,
                color: "gray",
                fontFamily: `"Segoe UI", Roboto, Helvetica, Arial, sans-serif`,
                fontSize: "14px",
              },
              "& .Mui-selected": { color: "#345c85" },
            }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.id} label={tab.label} />
            ))}
          </Tabs>

          {/* Tab Content */}
          <Box sx={{ mt: 3, p: 2, border: "1px solid #ddd", borderRadius: 2, boxShadow: 1 }}>
            {tabs[activeTab].content}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SmartOptionsAI;
