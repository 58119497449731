import { AnyAction, createReducer } from "@reduxjs/toolkit";
import {
  addAccountResolved,
  deleteAccountResolved,
  editAccountResolved,
  getAccountBalanceResolved,
  getAccountResolved,
  resetAccount,
} from "redux/Account/Account.actions";
import { AccountStoreState } from "redux/Account/Account.types";

export const initialState: AccountStoreState = {};

interface AccountReducer {
  [x: string]: (
    state: AccountStoreState,
    action: AnyAction
  ) => AccountStoreState | undefined;
}

const reducer: AccountReducer = {
  [resetAccount.toString()]: () => {
    return initialState;
  },
  [getAccountResolved.toString()]: (state, action) => {
    return {
      ...action.payload,
    };
  },
  [getAccountBalanceResolved.toString()]: (state, action) => {
    const updatedBalances = action.payload as {
      [key: string]: {
        netWorth?: number;
        optionBuyingPower?: number;
        stockBuyingPower?: number;
        capitalAllocation?: number;
      } | undefined;
    };
  
    const newState = { ...state };
  
    Object.entries(updatedBalances).forEach(([key, balanceData]) => {
      if (newState[key]) {
        newState[key] = {
          ...newState[key],
          ...(balanceData || {}), // Only spread if balanceData is defined
        };
      }
    });
  
    return newState;
  },  
  [addAccountResolved.toString()]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      description: action.payload.description,
    },
  }),
  [editAccountResolved.toString()]: (state, action) => ({
    ...state,
    [action.payload.id]: {
      description: action.payload.description,
    },
  }),
  [deleteAccountResolved.toString()]: (state, action) => {
    const newState = {
      ...state,
    };
    delete newState[action.payload];
    return newState;
  },
};

export default createReducer(initialState, reducer);
