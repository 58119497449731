import { createSelector } from "reselect";
import { StoreState } from "redux/store.types";
// const namespacedStore = (state: NamespacedStoreState) => state[moduleNamespace];
const storeState = (state: StoreState) => state;

export const selectorUIStore = createSelector(storeState, (state) => state.ui);

export const selectorAddEntryDrawer = createSelector(
  storeState,
  (state) => state?.ui.addEntryDrawer
);

export const selectorAddEntryFromOrdersDrawer = createSelector(
  storeState,
  (state) => state?.ui.addEntryFromOrdersDrawer
);

export const selectorCloseDrawer = createSelector(
  storeState,
  (state) => state?.ui.closeDrawer
);

export const selectorRollDrawer = createSelector(
  storeState,
  (state) => state?.ui.rollDrawer
);

export const selectorDetailsDrawer = createSelector(
  storeState,
  (state) => state?.ui.detailsDrawer
);

export const selectorDeleteDrawer = createSelector(
  storeState,
  (state) => state?.ui.deleteDrawer
);

export const selectorAddLegsDrawer = createSelector(
  storeState,
  (state) => state?.ui.addLegs
);

export const selectorAddLegsFromOrderDrawer = createSelector(
  storeState,
  (state) => state?.ui.addLegsFromOrder
);
export const selectorAddActivityTemplateDrawer = createSelector(
  storeState,
  (state) => state?.ui.addActivityTemplateDrawer
);

export const selectorAddActivityDrawer = createSelector(
  storeState,
  (state) => state?.ui.addActivityDrawer
);

export const selectorReviewOrderDrawer = createSelector(
  storeState,
  (state) => state?.ui.reviewOrderDrawer
);

export const selectorSelectionModel = createSelector(
  storeState,
  (state) => state?.ui.selectionModel
);

export const selectorFilterBySymbols = createSelector(
  storeState,
  (state) => state.ui.filterBySymbols
);

export const selectorFilterByStatus = createSelector(
  storeState,
  (state) => state.ui.filterByStatus
);

export const selectorFilterByAccount = createSelector(
  storeState,
  (state) => state.ui.filterByAccount
);

export const selectorFilterByDate = createSelector(
  storeState,
  (state) => state.ui.filterByDates
);

export const selectorSortModel = createSelector(
  storeState,
  (state) => state.ui.sortModel
);

export const selectorDetailsStockChart = createSelector(
  storeState,
  (state) => state.ui.detailsStockChart
);

export const selectorPage = createSelector(storeState, (state) =>
  state.ui.page && state.ui.page >= 0 ? state.ui.page : 0
);

export const selectorPageSize = createSelector(storeState, (state) =>
  state.ui.pageSize && state.ui.pageSize >= 0 ? state.ui.pageSize : 100
);

export const selectorChangeCustomStrategyDialog = createSelector(
  storeState,
  (state) => state?.ui.changeCustomStrategyDialog
);

export const selectorJournalTableColumnHidden = createSelector(
  selectorUIStore,
  (state) => state?.journalTableColumnHidden
);

export const selectorJournalTableColumnShown = createSelector(
  selectorUIStore,
  (state) => state?.journalTableColumnShown
);

export const selectorJournalTableVisibilityModel = createSelector(
  selectorUIStore,
  (state) => state?.journalTableVisibilityModel
);
