import { GridSize } from "@mui/material";
import { DateTime } from "luxon";
import moment from "moment";

export const getGridSize = (size: number): GridSize => {
  switch (size) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 5;
    case 6:
      return 6;
    case 7:
      return 7;
    case 8:
      return 8;
    case 9:
      return 9;
    case 10:
      return 10;
    case 11:
      return 11;
    case 12:
      return 12;
    default:
      return "auto";
  }
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const currency2DigitFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

export const gridInteger = (value: number) => {
  if (value) return currencyFormatter.format(value);
  else return value;
};

export const grid2Decimal = (params: number) => {
  if (params) return currency2DigitFormatter.format(+params);
  else return params;
};

export const gridInfinity = (params: number) => {
  if (params == null) {
    return Number.POSITIVE_INFINITY;
  } else return currencyFormatter.format(+params);
};

export const gridPercentFormatter = (params: number) => {
  if (params) return `${Math.round(+params * 100)}%`;
  else return params;
};

export const gridDateFormatter = (params: string) => {
  if (params) return getMonthDateString(new Date(params));
  else return params;
};

export const gridOrderTypeFormatter = (params: string) => {
  switch (params) {
    case "sto":
      return "Sell to Open";
    case "stc":
      return "Sell to Close";
    case "bto":
      return "Buy to Open";
    case "btc":
      return "Buy to Close";
    default:
      return "";
  }
};

export const leadingZero = (n: number, width: number, z?: string) => {
  z = z || "0";
  const n2 = n + "";
  return n2.length >= width
    ? n2
    : new Array(width - n2.length + 1).join(z) + n2;
};

export const getNowDateString = (separator = "-") => {
  const now = new Date();
  return getDateString(now, separator);
};

export const getMonthDateString = (date: Date) => {
  return date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
  });
};

export const getDateString = (date: Date, separator = "-") => {
  const now = date;
  return `${now.getFullYear()}${separator}${leadingZero(
    now.getMonth() + 1,
    2
  )}${separator}${leadingZero(now.getDate(), 2)}`;
};

export const stringToUTC = (dateString: string | undefined) => {
  if (!dateString) return "";

  if (dateString.indexOf("-") >= 0) {
    const parts = dateString.split("-");
    const now = new Date();
    const mydate = new Date(
      Number(parts[0]),
      Number(parts[1]) - 1,
      Number(parts[2]),
      now.getHours(),
      now.getMinutes(),
      now.getSeconds()
    );
    const utcString = mydate.toUTCString();
    return utcString;
  } else {
    return moment(dateString, "M/D/YYYY").format();
  }
};

export const calculateDTE = (expDate: any): number => {
  const today = new Date();
  const expiration = new Date(expDate);

  // Convert both dates to UTC (ignoring time)
  const todayUTC = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate());
  const expirationUTC = Date.UTC(expiration.getUTCFullYear(), expiration.getUTCMonth(), expiration.getUTCDate());

  // Calculate difference in days and adjust
  return Math.floor((expirationUTC - todayUTC) / (1000 * 3600 * 24)) + 1;
};


export const toUTCString = (date: any, format: string = "yyyy-MM-dd"): string => {
  // console.log("toUTCString input:", date);

  // Ensure date is a valid Date object
  const dateTime = date instanceof Date
    ? DateTime.fromJSDate(date).toUTC() // Convert JavaScript Date to UTC
    : DateTime.fromISO(date, { zone: "utc" }); // Convert ISO string to Luxon DateTime in UTC

  if (!dateTime.isValid) {
    console.error("Invalid DateTime:", date);
    return "Invalid Date";
  }

  const utcStr = dateTime.toFormat(format); // Format the UTC DateTime
  // console.log("utcStr:", utcStr);
  return utcStr;
};

export const toUTCDate = (dateStr: string) => {
  // const date = new Date(dateStr);
  // return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
  return new Date(dateStr);
};

export const deepSet = (obj: any, pathArray: string[], value: any) => {
  let current = obj;

  for (let i = 0; i < pathArray.length - 1; i++) {
    const key = pathArray[i];
    if (!(key in current)) {
      current[key] = {};
    }
    current = current[key];
  }

  current[pathArray[pathArray.length - 1]] = value;
};