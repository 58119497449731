import { createAction } from "@reduxjs/toolkit";
import { ActivityTemplateDeleteBody, ActivityTemplateDuplicateBody, addActivityPostBody, AddActivityTemplatePostBody, cancelOrderBody, GetActivitiesParams, GetActivitiesResponse, GetActivityTemplatesParams, GetActivityTemplatesResponse, GetRuleFieldsResolvedResponse, templateToActivityPostBody, TradeFormCache } from "./SmartOptionsAI.types";
import { ActivityTemplate, AdvancedActivity } from "journal-lib";

export const resetActivityTemplates = createAction("activityTemplate/reset");

export const getActivityTemplates = createAction<GetActivityTemplatesParams>(
    "activityTemplate/getActivityTemplates"
);

export const getActivityTemplatesResolved = createAction<GetActivityTemplatesResponse>(
    "activityTemplate/getActivityTemplatesResolved"
);

export const getActivities = createAction<GetActivitiesParams>(
    "activity/getActivities"
);

export const getActivitiesResolved = createAction<GetActivitiesResponse>(
    "activity/getActivitiesResolved"
);

export const getLegRuleFields = createAction(
    "activityTemplate/getLegRuleFields"
);

export const getLegRuleFieldsResolved = createAction<GetRuleFieldsResolvedResponse>(
    "activityTemplate/getLegRuleFieldsResolved"
);

export const getTotalRuleFields = createAction(
    "activityTemplate/getTotalRuleFields"
);

export const getTotalRuleFieldsResolved = createAction<GetRuleFieldsResolvedResponse>(
    "activityTemplate/getTotalRuleFieldsResolved"
);

export const addActivityTemplate = createAction<AddActivityTemplatePostBody>("activityTemplate/addActivityTemplate");

export const addActivityTemplateResolved = createAction<ActivityTemplate>(
    "activityTemplate/addActivityTemplateResolved"
);

export const addActivityTemplateRejected = createAction("activityTemplate/addActivityTemplateRejected");

export const deleteActivityTemplate = createAction<ActivityTemplateDeleteBody>("activityTemplate/deleteActivityTemplate");

export const deleteActivityTemplateResolved = createAction<ActivityTemplateDeleteBody>(
    "activityTemplate/deleteActivityTemplateResolved"
);

export const deleteActivityTemplateRejected = createAction(
    "activityTemplate/deleteActivityTemplateRejected"
);

export const editActivityTemplate = createAction<AddActivityTemplatePostBody>("activityTemplate/editActivityTemplate");

export const editActivityTemplateResolved = createAction<ActivityTemplate>("activityTemplate/editActivityTemplateResolved");

export const editActivityTemplateRejected = createAction("activityTemplate/editActivityTemplateRejected");

export const duplicateActivityTemplate = createAction<ActivityTemplateDuplicateBody>("activityTemplate/duplicateActivityTemplate");

export const duplicateActivityTemplateResolved = createAction<ActivityTemplate>("activityTemplate/duplicateActivityTemplateResolved");

export const duplicateActivityTemplateRejected = createAction("activityTemplate/duplicateActivityTemplateRejected");

export const templateToActivity = createAction<templateToActivityPostBody>("activityTemplate/templateToActivity");

export const templateToActivityResolved = createAction<AdvancedActivity>(
    "activityTemplate/templateToActivityResolved"
);

export const templateToActivityRejected = createAction(
    "activityTemplate/templateToActivityRejected"
);

export const addActivity = createAction<addActivityPostBody>("activity/addActivity");

export const addActivityResolved = createAction<AdvancedActivity>(
    "activity/addActivityResolved"
);

export const addActivityRejected = createAction(
    "activity/addActivityRejected"
);

export const editActivity = createAction<addActivityPostBody>("activity/editActivity");

export const editActivityResolved = createAction<AdvancedActivity>(
    "activity/editActivityResolved"
);

export const editActivityRejected = createAction(
    "activity/editActivityRejected"
);

export const cancelOrder = createAction<cancelOrderBody>("activity/cancelOrder");

export const cancelOrderResolved = createAction(
    "activity/cancelOrderResolved"
);

export const setTradeFormCache = createAction<TradeFormCache>(
    "activity/setTradeFormCache"
);

export const setSymbolFilter = createAction<string>(
    "activity/setSymbolFilter"
);

export const setStatusFilter = createAction<string>("activity/setStatusFilter");

export const setAccountFilter = createAction<string>("activity/setAccountFilter");

export const setStartDateFilter = createAction<string>("activity/setStartDateFilter");

export const setEndDateFilter = createAction<string>("activity/setEndDateFilter");