import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Menu,
    Typography,
    IconButton,
    Box,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Drawer,
    Snackbar,
    Alert,
    CircularProgress,
} from "@mui/material";
import LoaderButton from "components/LoaderButton";
import { selectorAddActivityDrawer } from "redux/UI/UI.selectors";
import { toggleAddActivityDrawer, toggleReviewOrderDrawer } from "redux/UI/UI.actions";
import { calculateDTE, deepSet, toUTCString, usePhoneScreenMedia } from "utils";
import { selectorTradeFormCache, setTradeFormCache } from "redux/SmartOptionsAI";
import _ from "lodash";
import { ActivityLeg, AdvancedActivity, Order_PriceEffect, Order_TimeInForce } from "journal-lib";
import { getAccount, selectorAccountStore } from "redux/Account";
import { useAddActivityStyles } from "./AddActivity.styles";
import { Add, ChevronLeft, ChevronRight, Delete, MoreVert } from "@mui/icons-material";
import { OptionChains } from "components/Charts/Settings/Modeling/OptionChains/OptionChains";
import { ModelQty, OptionChains as OptionChainsInterface, setModelQty } from "redux/Analysis";
import { API } from "aws-amplify";

interface AddActivityProps {
    activity?: AdvancedActivity | null;
    setActivity: (activity: AdvancedActivity) => void;
    bReplaceOrder?: boolean;
}

const AddActivity = ({ activity, setActivity, bReplaceOrder }: AddActivityProps) => {
    const accounts = useSelector(selectorAccountStore);
    const accountList: { value: string; label: string }[] = Object.keys(
        accounts
    ).map((accountId) => {
        return {
            value: accountId,
            label: accounts[accountId].description,
        };
    });

    const classes = useAddActivityStyles(); // Use the styles

    const dispatch = useDispatch();
    const isOpen = useSelector(selectorAddActivityDrawer);
    const isPhoneScreen = usePhoneScreenMedia();

    const tradeFormCache = useSelector(selectorTradeFormCache);

    const [showError, setShowError] = useState(false);
    const [localId, setLocalId] = useState(activity?.id ?? "");
    const [localSymbol, setLocalSymbol] = useState((activity?.symbol ?? tradeFormCache?.symbol)?.toUpperCase());
    // const [validSymbol, setValidSymbol] = useState((activity?.symbol ?? "").toUpperCase());
    const [localDesc, setLocalDesc] = useState(activity?.description ?? "");
    const [localAccountId, setLocalAccountId] = useState(activity?.accountId ?? tradeFormCache?.accountId);
    const [localLegs, setLocalLegs] = useState(activity?.legs);

    const [localOrderType, setLocalOrderType] = useState(activity?.order?.["order-type"] ?? "Limit");
    const [localPrice, setLocalPrice] = useState(activity?.order?.price ?? 0);
    const [localPriceEffect, setLocalPriceEffect] = useState(activity?.order?.["price-effect"] ?? "Credit");
    const [localTIF, setLocalTIF] = useState(activity?.order?.["time-in-force"] ?? "Day");
    const [isLoading, setIsLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    // const [selectedLeg, setSelectedLeg] = useState<ActivityLeg | undefined>(undefined);
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const [localOptionsChains, setLocalOptionChains] = useState<OptionChainsInterface>({});
    const [localModelQty, setLocalModelQty] = useState<ModelQty>({});

    const [inputIndexAndQty, setInputIndexAndQty] = useState([-1, '']);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const legsToModelQty = (legs: ActivityLeg[] | undefined) => {
        if (!legs) return {};
        const modelQty: ModelQty = {};
        for (const leg of legs) {
            if (leg.leg && leg.leg.option) {
                if (leg.leg.option.type == "call" || leg.leg.option.type == "put") {
                    if (leg.leg.option.expDate && leg.leg.option.strike) {
                        const exp = toUTCString(leg.leg.option.expDate, "yyyy-MM-dd");
                        const strike = leg.leg.option.strike;
                        const putCall = leg.leg.option.type;
                        if (exp && strike && putCall && leg.leg.qty) {
                            deepSet(modelQty, [exp, String(strike), putCall], leg.leg.qty);
                        }
                        //Cannot set like this way
                        // modelQty[exp][strike][leg.leg.option.type] = leg.leg.qty;
                    }
                }
            }
        }
        return modelQty;
    };

    const modelQtyToLegs = (symbol: string, modelQty: ModelQty): ActivityLeg[] => {

        const legs: ActivityLeg[] = [];

        for (const expDateStr in modelQty) {
            const strikeMap = modelQty[expDateStr];
            const expDate = new Date(expDateStr);

            for (const strikeStr in strikeMap) {
                const qtyObj = strikeMap[strikeStr];
                const strike = parseFloat(strikeStr);

                if (qtyObj.put) {
                    legs.push({
                        type: qtyObj.put > 0 ? 'bto' : 'sto', // adjust if `side` is a different type
                        leg: {
                            qty: Number(qtyObj.put),
                            option: {
                                type: 'put',
                                strike,
                                expDate,
                                symbol: symbol, // populate as needed
                            },
                        },
                    });
                }

                if (qtyObj.call) {
                    legs.push({
                        type: qtyObj.call > 0 ? 'bto' : 'sto',
                        leg: {
                            qty: Number(qtyObj.call),
                            option: {
                                type: 'call',
                                strike,
                                expDate,
                                symbol: symbol, // populate as needed
                            },
                        },
                    });
                }
            }
        }

        return legs;
    };

    const handleQtyChange = (index: number, value: any) => {
        if (!localLegs || !value) return;
        // const updatedLegs = localLegs ? [...localLegs] : [];
        // if (updatedLegs[index]?.leg) { // Ensures both updatedLegs[index] and updatedLegs[index].leg exist
        //     updatedLegs[index]!.leg!.qty = Number(value);
        //     setLocalLegs(updatedLegs);
        // }
        const leg = localLegs[index];
        if (leg && leg.leg) {
            const expDateStr = toUTCString(leg.leg.option.expDate, "yyyy-MM-dd");
            const strike = leg.leg.option.strike;
            const putCall = leg.leg.option.type;
            const newModelQty = { ...localModelQty };
            if (expDateStr && strike && putCall && newModelQty) {
                deepSet(newModelQty, [expDateStr, String(strike), putCall], value);
                setLocalModelQty(newModelQty);
            }
        }

        setInputIndexAndQty([-1, '']);
    };

    const handleLocateLeg = () => {
        // setLocalLegs(localLegs?.filter((_, index) => index !== selectedLeg));
        if (selectedIndex != null && localLegs && selectedIndex < localLegs.length) {
            //...
        }
        handleMenuClose();
    };

    const handleDeleteLeg = () => {
        // setLocalLegs(localLegs?.filter((_, index) => index !== selectedLeg));
        if (selectedIndex != null && localLegs && selectedIndex < localLegs.length) {
            const newLegs = [...localLegs.slice(0, selectedIndex), ...localLegs.slice(selectedIndex + 1)];
            const newModelQty = legsToModelQty(newLegs);
            setLocalModelQty(newModelQty);
        }
        handleMenuClose();
    };


    useEffect(() => {
        dispatch(getAccount());
    }, [
        dispatch,
    ]);

    const leadingZero = (n: number, width: number, z = "0") => {
        z = z || "0";
        const n2 = n + "";
        return n2.length >= width
            ? n2
            : new Array(width - n2.length + 1).join(z) + n2;
    };

    const getDateString = (date: Date, includeTime = false) => {
        let dateStr = `${date.getFullYear()}-${leadingZero(
            date.getMonth() + 1,
            2
        )}-${leadingZero(date.getDate(), 2)}`;
        if (includeTime) {
            dateStr += ` ${leadingZero(date.getHours(), 2)}:${leadingZero(
                date.getMinutes(),
                2
            )}:${leadingZero(date.getSeconds(), 2)}`;
        }

        return dateStr;
    };

    const getDateKeyString = (date: any, includeTime = false) => {
        let dateStr = "";
        let realDate = new Date(1000, 1, 1);
        if (date) {
            try {
                if (Object.prototype.toString.call(date) === "[object Date]") {
                    realDate = date;
                } else {
                    realDate = new Date(date);
                }
                dateStr = getDateString(realDate, includeTime);
            } catch (e) {
                console.error(`date: ${date}, dateStr: ${dateStr}`, e);
            }
        }

        return dateStr;
    }

    useEffect(() => {
        const legs = modelQtyToLegs(localSymbol, localModelQty);
        legs.sort((legA, legB) => {

            const a = legA.leg;
            const b = legB.leg;
            if (!a || !b) return 0;

            let dateA = getDateKeyString(a.option.expDate);
            let dateB = getDateKeyString(b.option.expDate);

            if (dateA > dateB) {
                return 1;
            } else if (dateA === dateB) {
                if (a.option.type > b.option.type) {
                    return -1;
                } else if (a.option.type === b.option.type) {
                    if (Number(a.option.strike) > Number(b.option.strike)) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else {
                    return 1;
                }
            } else {
                return -1;
            }
        });
        setLocalLegs(legs);
    }, [
        localModelQty,
    ]);

    useEffect(() => {

        console.log("AddActivity", activity);

        const symbol = (activity?.symbol ?? tradeFormCache?.symbol)?.toUpperCase()
        setLocalSymbol(symbol);
        setLocalDesc(activity?.description ?? "");
        setLocalAccountId(activity?.accountId ?? tradeFormCache?.accountId);
        // setLocalLegs(activity?.legs ?? []);
        // setLocalLegs(sampleLegs);
        setLocalModelQty(legsToModelQty(activity?.legs));

        setLocalOrderType(activity?.order?.["order-type"] ?? "Limit");
        setLocalPrice(Math.round((activity?.order?.price ?? 0) * 100) / 100);
        setLocalPriceEffect(activity?.order?.["price-effect"] ?? "Credit");
        setLocalTIF(activity?.order?.["time-in-force"] ?? "Day");

        if (symbol) {
            onSymbolChange();
        }
    }, [
        activity
    ]);

    const onSymbolChange = () => {
        if (localSymbol) {
            API.put("optionOrder", "/journalEntry/getOptionChain", {
                body: {
                    symbol: localSymbol,
                    type: "putCall",
                },
            }).then((res) => {
                if (res == undefined || !Array.isArray(res) || res.length == 0) {
                    setLocalOptionChains({});
                }
                else {
                    let optionChains: OptionChainsInterface = {};
                    res.sort((a: any, b: any) => {
                        if (a.expDate == b.expDate) {
                            return a.strike - b.strike;
                        } else {
                            return new Date(a.expDate).getTime() - new Date(b.expDate).getTime();
                        }
                    });
                    // console.log(res);
                    res.map((item: any) => {
                        const expDateStr = toUTCString(item.expDate, "yyyy-MM-dd");
                        if (optionChains[expDateStr] == undefined) {
                            optionChains[expDateStr] = {};
                        }
                        if (optionChains[expDateStr][item.strike] == undefined) {
                            optionChains[expDateStr][item.strike] = {};
                        }
                        item.isOption = true;
                        optionChains[expDateStr][item.strike][item.type] = { ...item };
                    });
                    setLocalOptionChains(optionChains);
                }
            })
                .catch((error) => {
                    // Log error details
                    // console.error(" API error:", error);

                    // Optional: Show user-friendly message or handle specific error codes
                    if (error.response) {
                        // console.error("Status code:", error.response.status);
                        if (error.response.data) {
                            const errorCode = error.response.data.errorCode;
                            const errorMsg = error.response.data.errorMsg;
                            console.error(errorMsg);
                        }

                    } else if (error.message) {
                        console.error(error.message);
                    }
                });
        }

        // if (localSymbol) {
        //     API.put("optionOrder", "/journalEntry/getOptionChain", {
        //         body: {
        //             symbol: localSymbol,
        //             type: "putCall",
        //         },
        //     }).then((res) => dispatch(setOptionChains(res)));
        // }
        // else {
        //     dispatch(setOptionChains([]));
        // }
        // setValidSymbol(localSymbol);
    };

    const togglePanel = () => {
        setIsPanelOpen(!isPanelOpen);
    };

    const getEditBody = () => ({
        totalPrice: localPriceEffect == "Credit" ? -Math.abs(localPrice) : Math.abs(localPrice),
        activity: {
            id: localId,
            description: localDesc,
            symbol: localSymbol.toUpperCase(),
            identityId: "",
            accountId: localAccountId,

            legs: localLegs ?? [],
        }
    });

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!localAccountId) {
            setErrorMessage("Please select an account.");
            setShowSnackbar(true);
            return;
        }
        if (!localSymbol) {
            setErrorMessage("Symbol is required.");
            setShowSnackbar(true);
            return;
        }
        if (!localLegs || localLegs.length === 0) {
            setErrorMessage("At least one leg is required.");
            setShowSnackbar(true);
            return;
        }

        const existingId = activity?.id;

        setIsLoading(true);
        try {
            // if (activity) {
            //     dispatch(editActivity(getEditBody()));
            // }
            // else {
            //     dispatch(addActivity(getEditBody()));
            // }
            API.post("optionOrder", "/smartAI/generateOrder", {
                body: getEditBody(),
            }).then((res) => {
                console.log("generateOrder result", res);
                if (bReplaceOrder) {
                    res.id = existingId;
                }
                setActivity(res);
                dispatch(toggleAddActivityDrawer(false));
                dispatch(toggleReviewOrderDrawer(true));
            })
                .catch((error) => {
                    // Log error details
                    // console.error(" API error:", error);

                    // Optional: Show user-friendly message or handle specific error codes
                    if (error.response) {
                        // console.error("Status code:", error.response.status);
                        if (error.response.data) {
                            const errorCode = error.response.data.errorCode;
                            const errorMsg = error.response.data.errorMsg;
                            // console.error(errorMsg);
                            setErrorMessage(errorMsg);
                            setShowSnackbar(true);
                        }

                    } else if (error.message) {
                        // console.error(error.message);
                        setErrorMessage(error.message);
                        setShowSnackbar(true);
                    }
                });

        } finally {
            setIsLoading(false);
        }
    };

    const onClosing = () => {
        dispatch(toggleAddActivityDrawer(false));
        const tradeCache = { accountId: localAccountId ?? tradeFormCache?.accountId, symbol: localSymbol ?? tradeFormCache?.symbol };
        if (tradeCache.accountId && tradeCache.symbol) {
            dispatch(setTradeFormCache(tradeCache));
        }
        setIsLoading(false);
        setShowError(false);
    };

    const reset = () => {
        // setLocalSymbol("");
        setLocalDesc("");
        // setLocalAccountId("");
        setLocalLegs([]);
        setModelQty({});

        setLocalOrderType("Limit");
        setLocalPrice(0);
        setLocalPriceEffect("Credit");
        setLocalTIF("Day");

        setSelectedIndex(null);
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    return (
        <>
            <Dialog
                key={activity?.id || "New"}
                open={isOpen}
                // onClose={onClosing}
                fullWidth
                maxWidth="lg"
                fullScreen={isPhoneScreen}
                TransitionProps={{
                    onExited: reset,
                }}
            >
                <form onSubmit={handleSubmit} onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        // Prevent form submission unless it's from a textarea or explicitly allowed input
                        e.preventDefault();
                    }
                }} style={{ width: "100%" }}>
                    <Box display="flex" width="100%" height="70vh" position="relative">
                        {/* Main Content */}
                        <Box
                            flex={1}
                            display="flex"
                            flexDirection="column"
                            width={isPanelOpen ? "50%" : "100%"}
                            height="100%"
                            overflow="hidden">
                            <DialogTitle>Order</DialogTitle>
                            <DialogContent
                                style={{
                                    flex: 1,
                                    overflowY: "auto",
                                    paddingBottom: 0,  // Reduce bottom padding
                                    minHeight: "auto", // Let it adjust dynamically
                                    maxHeight: "calc(70vh - 160px)", // Adjust this to fit better
                                }}>
                                <Grid container spacing={2} style={{ padding: "0 20px", alignItems: "center", marginBottom: 0 }}>
                                    {/* Account Dropdown */}
                                    <Grid item xs={4}>
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Account</InputLabel>
                                        <Select
                                            fullWidth
                                            value={localAccountId}
                                            onChange={(e) => setLocalAccountId(e.target.value)}
                                            variant="standard"
                                            disabled={bReplaceOrder}
                                        >
                                            {accountList.map(account => (
                                                <MenuItem key={account.value} value={account.value}>
                                                    {account.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>

                                    {/* Symbol Input */}
                                    <Grid item xs={4} >
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Symbol</InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={localSymbol}
                                            onChange={(e) => {
                                                setLocalSymbol(e.target.value.toUpperCase())
                                            }}
                                            onBlur={() => {
                                                onSymbolChange();
                                            }}
                                            InputProps={{ readOnly: bReplaceOrder }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    e.preventDefault(); // Prevents unintended form submission
                                                    onSymbolChange();  // Call the same function as onBlur
                                                }
                                            }}
                                        />
                                    </Grid>

                                    {/* Description Input */}
                                    <Grid item xs={4} >
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Description</InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            value={localDesc}
                                            onChange={(e) => setLocalDesc(e.target.value)}
                                            InputProps={{ readOnly: bReplaceOrder }}
                                        />
                                    </Grid>

                                    {/* Price Inputs */}
                                    <Grid item xs={4} >
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Total Limit Price</InputLabel>
                                        <TextField
                                            fullWidth
                                            variant="standard"
                                            type="number"
                                            value={localPrice}
                                            onChange={(e) => setLocalPrice(Number(e.target.value))}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Debit/Credit</InputLabel>
                                        <Select
                                            fullWidth
                                            value={localPriceEffect}
                                            onChange={(e) => setLocalPriceEffect(e.target.value as Order_PriceEffect)}
                                            variant="standard"
                                        >
                                            <MenuItem value="Credit">Credit</MenuItem>
                                            <MenuItem value="Debit">Debit</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4} >
                                        <InputLabel sx={{ fontSize: 12, color: "gray" }}>Time-in-Force</InputLabel>
                                        <Select
                                            fullWidth
                                            value={localTIF}
                                            onChange={(e) => setLocalTIF(e.target.value as Order_TimeInForce)}
                                            variant="standard"
                                        >
                                            <MenuItem value="Day">Day</MenuItem>
                                            <MenuItem value="GTC">GTC</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{ mt: 3, mb: 1, margin: "4px" }}>Legs</Typography>
                                        <Box sx={{ border: "1px solid #ccc", borderRadius: "4px", padding: "16px", mt: 1, margin: "4px 4px" }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Qty</TableCell>
                                                        <TableCell>Exp Date</TableCell>
                                                        <TableCell>Days to Expiry</TableCell>
                                                        <TableCell>Strike</TableCell>
                                                        <TableCell>Type</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {localLegs?.map((leg, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>
                                                                <TextField
                                                                    type="text"
                                                                    variant="standard"
                                                                    value={inputIndexAndQty[0] == index ? inputIndexAndQty[1] : leg.leg?.qty}
                                                                    onChange={(e) => {
                                                                        const val = e.target.value;
                                                                        // Allow empty string or "-"
                                                                        if (val === '' || val === '-' || /^-?\d*$/.test(val)) {
                                                                            setInputIndexAndQty([index, val]);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const parsed = Number(e.target.value);
                                                                        if (!isNaN(parsed)) {
                                                                            handleQtyChange(index, parsed);
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            e.preventDefault(); // Prevents unintended form submission
                                                                            handleQtyChange(Number(inputIndexAndQty[0]), inputIndexAndQty[1]);  // Call the same function as onBlur
                                                                        }
                                                                    }}
                                                                    sx={{ width: "50px" }}
                                                                    InputProps={{ readOnly: bReplaceOrder }}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{new Date(leg.leg?.option.expDate ?? "").toLocaleDateString()}</TableCell>
                                                            <TableCell>{calculateDTE(leg.leg?.option.expDate)}d</TableCell>
                                                            <TableCell>{leg.leg?.option.strike}</TableCell>
                                                            <TableCell>
                                                                <Typography color={leg.type === "bto" ? "green" : "red"}>
                                                                    {leg.type.toUpperCase()}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                                                    <MoreVert />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {/* <TableRow>
                                <TableCell colSpan={6} align="center">
                                    <Button variant="outlined" startIcon={<Add />} onClick={handleAddLeg}>
                                        Add Leg
                                    </Button>
                                </TableCell>
                            </TableRow> */}
                                                </TableBody>
                                            </Table>
                                        </Box>

                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                            <MenuItem onClick={handleLocateLeg}>Locate</MenuItem>
                                            <MenuItem onClick={handleDeleteLeg}>Delete</MenuItem>
                                        </Menu>
                                    </Grid>

                                </Grid>
                            </DialogContent>

                            {/* Dialog Actions */}
                            <DialogActions style={{ padding: "16px", justifyContent: "flex-end" }}>
                                <Button color="secondary" onClick={onClosing}>
                                    Cancel
                                </Button>
                                <LoaderButton type="submit" isLoading={isLoading} color="primary" disabled={isLoading} startIcon={isLoading && <CircularProgress size={20} />}>
                                    Review & Send
                                </LoaderButton>
                            </DialogActions>
                        </Box>

                        {/* Right-Side Panel */}
                        <Box
                            flex={1}
                            display={isPanelOpen ? "flex" : "none"}
                            flexDirection="column"

                            p={3}
                            width="50%"
                            height="100%"
                            overflow="auto"
                        >
                            <OptionChains optionChains={localOptionsChains} setOptionChains={setLocalOptionChains} modelQty={localModelQty} setModelQty={setLocalModelQty} />
                        </Box>

                        {/* Toggle Button */}
                        <IconButton
                            onClick={togglePanel}
                            style={{
                                position: "absolute",
                                top: "50%",
                                transform: "translateY(-50%)",
                                right: isPanelOpen ? "10px" : "10px",
                                zIndex: 2
                            }}
                        >
                            {isPanelOpen ? <ChevronLeft /> : <ChevronRight />}
                        </IconButton>
                    </Box>
                </form>
            </Dialog>

            <Snackbar
                open={showSnackbar}
                autoHideDuration={4000}
                onClose={() => setShowSnackbar(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    onClose={() => setShowSnackbar(false)}
                >
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );

};

export default AddActivity;
