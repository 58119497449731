import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Add,
  Dns,
  ExitToApp,
  ListAlt,
  Settings,
  ShowChart,
  SmartToy,
  VpnKey,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import { Auth } from "aws-amplify";
import clsx from "clsx";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useAppContext } from "libs/contextLib";
import { toggleAddEntryDrawer } from "redux/UI";
import { useDispatch } from "react-redux";
import { createTheme } from "@mui/material";
import { resetAccount } from "redux/Account";
import { useFeatureValue } from "@growthbook/growthbook-react";

const theme = createTheme();

const useStyles = makeStyles(() => ({
  root: {},
  toolbar: theme.mixins.toolbar,
  appBar: {
    boxShadow:
      "0px 2px 4px -4px rgb(0 0 0 / 26%), 0px 4px 5px -4px rgb(0 0 0 / 17%), 0px 1px 10px -10px rgb(0 0 0 / 14%)",
    background: "white",
  },
  sticky: {
    position: "sticky",
    top: "0px",
  },
}));

export const MenuList = ({ sticky = false }: { sticky?: boolean }) => {
  const isFeedEnabled = useFeatureValue("feed", false);
  const isOptionBotsEnabled = true;//useFeatureValue("optionBots", false);
  const classes = useStyles();
  const navigate = useNavigate();
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  // const [isAuthenticated, userHasAuthenticated] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   onLoad();
  // }, []);

  const menu = [
    {
      name: "Add Entry",
      onClick: () => dispatch(toggleAddEntryDrawer()),
      icon: <Add />,
    },
    {
      name: "Journal",
      onClick: () => navigate("/"),
      icon: <ListAlt />,
    },
    {
      name: "Smart Options",
      onClick: () => navigate("/optionbots"),
      icon: <SmartToy />,
      enabled: isOptionBotsEnabled,
    },
    {
      name: "P/L Stats",
      onClick: () => navigate("/plstats"),
      icon: <MonetizationOnIcon />,
    },
    {
      name: "Ticker",
      onClick: () => navigate("/symbol"),
      icon: <ShowChart />,
    },
    {
      name: "News Feed",
      onClick: () => navigate("/newsfeed"),
      icon: <Dns />,
      enabled: isFeedEnabled,
    },
    {
      name: "Setting",
      onClick: () => navigate("/settings"),
      icon: <Settings />,
    },
    {
      name: "Log Out",
      onClick: () => {
        dispatch(resetAccount());
        handleLogout();
      },
      icon: <ExitToApp />,
    },
  ];

  const unAuthMenu = [
    {
      name: "Login",
      onClick: () => navigate("/login"),
      icon: <VpnKey />,
    },
    {
      name: "Sign Up",
      onClick: () => navigate("/signup"),
      icon: <Add />,
    },
  ];
  // async function onLoad() {
  //   try {
  //     await Auth.currentSession();
  //     userHasAuthenticated(true);
  //   } catch (e) {
  //     if (e !== "No current user") {
  //       onError(e);
  //     }
  //   }

  //   setIsAuthenticating(false);
  // }

  async function handleLogout() {
    await Auth.signOut();

    if (userHasAuthenticated) userHasAuthenticated(false);

    navigate("/login");
  }

  return (
    <Box className={clsx({ [classes.sticky]: sticky })}>
      <div className={clsx(classes.toolbar, classes.appBar)} />
      <Divider />
      <List>
        {isAuthenticated
          ? menu
              .filter((item) => {
                if (item.enabled == undefined) return true;
                if (item.enabled) return true;

                return false;
              })
              .map((item) => (
                <ListItemButton key={item.name} onClick={() => item.onClick()}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItemButton>
              ))
          : unAuthMenu.map((item) => (
              <ListItemButton key={item.name} onClick={() => item.onClick()}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            ))}
      </List>
    </Box>
  );
};
