import React, { useEffect, useState } from "react";
import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
    Menu, MenuItem,
    Box,
    Typography,
    TextField
} from "@mui/material";
import { Add, Edit, Delete, MoreVert, ContentCopy, CheckCircle, Cancel, FiberNew, Send, Repeat } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { toggleAddActivityDrawer, toggleAddActivityTemplateDrawer } from "redux/UI";
import AddActivityTemplate from "components/SmartOptionsAI/ActivityTemplate/AddActivityTemplate/AddActivityTemplate.component";
import { ActivityStatus, ActivityTemplate, AdvancedActivity } from "journal-lib";
import { cancelOrder, deleteActivityTemplate, duplicateActivityTemplate, getActivities, getActivityTemplates, getLegRuleFields, getTotalRuleFields, selectorAccountFilter, selectorActivities, selectorActivityTemplates, selectorEndDateFilter, selectorStartDateFilter, selectorStatusFilter, selectorSymbolFilter, setAccountFilter, setEndDateFilter, setStartDateFilter, setStatusFilter, setSymbolFilter } from "redux/SmartOptionsAI";
import { getAccount, selectorAccountStore } from "redux/Account";
import { calculateDTE } from "utils";
import AddActivity from "../AddActivity/AddActivity.component";
import ReviewOrder from "../ReviewOrder/ReviewOrder.component";


const ActivityList = () => {

    const loadedActivities = useSelector(selectorActivities);
    const accounts = useSelector(selectorAccountStore);
    const accountList: { value: string; label: string }[] = Object.keys(
        accounts
    ).map((accountId) => {
        return {
            value: accountId,
            label: accounts[accountId].description,
        };
    });

    const [activities, setActivities] = useState<AdvancedActivity[]>(loadedActivities);
    const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [selectedActivity, setSelectedActivity] = useState<AdvancedActivity | null>(null);
    const [deleteIndex, setDeleteIndex] = useState<number | null>(null);

    const [modifiedActivity, setModifiedActivity] = useState<AdvancedActivity | null>(null);

    const [replaceOrder, setReplaceOrder] = useState<boolean>(false);

    const symbolFilter = useSelector(selectorSymbolFilter);
    const statusFilter = useSelector(selectorStatusFilter);
    const accountFilter = useSelector(selectorAccountFilter);
    const startDate = useSelector(selectorStartDateFilter);
    const endDate = useSelector(selectorEndDateFilter);

    const [localSymbolFilter, setLocalSymbolFilter] = useState(symbolFilter);
    const [localStatusFilter, setLocalStatusFilter] = useState<string | null>(statusFilter);
    const [localAccountFilter, setLocalAccountFilter] = useState<string | null>(accountFilter);
    const [localStartDate, setLocalStartDate] = useState<Date | null>(startDate ? new Date(startDate) : null);
    const [localEndDate, setLocalEndDate] = useState<Date | null>(endDate ? new Date(endDate) : null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getActivities({}));
        dispatch(getAccount());
    }, [
        dispatch,
    ]);

    useEffect(() => {
        setActivities(loadedActivities);
    }, [
        loadedActivities,
    ]);

    useEffect(() => {
        setLocalAccountFilter(accountFilter);
    }, [accountFilter]);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, index: number) => {
        setAnchorEl(event.currentTarget);
        setSelectedIndex(index);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedIndex(null);
    };

    const handleDeleteConfirm = () => {
        if (deleteIndex !== null) {
            // dispatch(deleteActivity({ id: activities[deleteIndex].id }));
            setSelectedIndex(null);
            setDeleteIndex(null);
        }
    };

    const getStatusString = (status: ActivityStatus | undefined): string => {
        switch (status) {
            case "new":
                return "New";
            case "rejected":
                return "Rejected";
            case "generateOrderFailed":
                return "Order Generate Failed";
            case "orderSubmitted":
                return "Order Submitted";
            case "orderFilled":
                return "Order Filled";
            case "orderRejected":
                return "Order Rejected";
            case "orderCancelled":
                return "Order Cancelled";
            case "orderExpired":
                return "Order Expired";
            case "orderRemoved":
                return "Order Removed";
            default:
                return "Unknown";
        }
    };

    const getDateString = (activity: AdvancedActivity): string => {
        if (!activity.status) {
            return "No Status";
        }

        let date: Date | null | undefined;

        switch (activity.status) {
            case "new":
                date = activity.createdDate;
                break;
            case "rejected":
                date = activity.rejectedDate;
                break;
            case "generateOrderFailed":
                date = activity.rejectedDate; // Assuming it falls under rejection
                break;
            case "orderSubmitted":
                date = activity.orderUpdatedDate;
                break;
            case "orderFilled":
                date = activity.orderUpdatedDate;
                break;
            case "orderRejected":
                date = activity.orderUpdatedDate;
                break;
            case "orderCancelled":
                date = activity.orderUpdatedDate;
                break;
            case "orderExpired":
                date = activity.orderUpdatedDate;
                break;
            case "orderRemoved":
                date = activity.orderUpdatedDate;
                break;
            default:
                return "Unknown Status";
        }

        return date ? new Date(date).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        }) : "N/A";
    };

    const getAccountName = (accountId: string): string => {
        if (accounts) {
            return accounts[accountId].description;
        }
        else {
            return accountId;
        }
    };

    // const calculateDTE = (expDate: any) => {
    //     const today = new Date();
    //     const expiration = new Date(expDate);
    //     const timeDiff = expiration.getTime() - today.getTime();
    //     return Math.ceil(timeDiff / (1000 * 3600 * 24));
    // };

    const getStatusIcon = (status: ActivityStatus | undefined) => {
        switch (status) {
            case "new":
                return <FiberNew style={{ color: "#9e9e9e", marginRight: "5px" }} />;
            case "orderSubmitted":
                return <Send style={{ color: "#4caf50", marginRight: "5px" }} />;
            case "orderFilled":
                return <CheckCircle style={{ color: "#2196f3", marginRight: "5px" }} />;
            case "generateOrderFailed":
            case "rejected":
            case "orderRejected":
            case "orderCancelled":
                return <Cancel style={{ color: "#f44336", marginRight: "5px" }} />;
            default:
                return null;
        }
    };

    const isEditable = selectedIndex !== null &&
        activities[selectedIndex] &&
        (activities[selectedIndex].status === "new" || activities[selectedIndex].status === "orderSubmitted");

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: "10px" }}>
                <Button variant="contained" startIcon={<Add />} onClick={() => {
                    setSelectedActivity(null);
                    dispatch(toggleAddActivityDrawer(true))
                }}>
                    New Order
                </Button>
            </div>
            <Box display="flex" gap={2} mb={2} flexWrap="wrap" alignItems="center">
                <TextField
                    label="Symbol"
                    value={localSymbolFilter}
                    onChange={(e) => {
                        setLocalSymbolFilter(e.target.value);
                        dispatch(setSymbolFilter(e.target.value))
                        dispatch(getActivities({}));
                    }}
                    size="small"
                />
                <TextField
                    label="Status"
                    select
                    value={localStatusFilter || ""}
                    onChange={(e) => {
                        setLocalStatusFilter(e.target.value);
                        dispatch(setStatusFilter(e.target.value))
                        dispatch(getActivities({}));
                    }}
                    size="small"
                    style={{ minWidth: 150 }}
                >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="orderSubmitted">Order Submitted</MenuItem>
                    <MenuItem value="orderFilled">Order Filled</MenuItem>
                    <MenuItem value="orderRejected">Order Rejected</MenuItem>
                    <MenuItem value="orderCancelled">Order Cancelled</MenuItem>
                    <MenuItem value="orderExpired">Order Expired</MenuItem>
                    <MenuItem value="orderRemoved">Order Removed</MenuItem>
                </TextField>
                <TextField
                    label="Account"
                    select
                    value={localAccountFilter}
                    onChange={(e) => {
                        setLocalAccountFilter(e.target.value);
                        dispatch(setAccountFilter(e.target.value)); // optional
                        dispatch(getActivities({}));
                    }}
                    size="small"
                    style={{ minWidth: 200 }}
                >
                    <MenuItem value="">All</MenuItem>
                    {accountList.map((account) => (
                        <MenuItem key={account.value} value={account.value}>
                            {account.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={localStartDate ? localStartDate.toISOString().split('T')[0] : ""}
                    onChange={(e) => {
                        const selectedDate = e.target.value ? new Date(e.target.value) : null;
                        setLocalStartDate(selectedDate);
                        dispatch(setStartDateFilter(e.target.value))
                        dispatch(getActivities({}));
                    }}
                    size="small"
                />
                <TextField
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={localEndDate ? localEndDate.toISOString().split('T')[0] : ""}
                    onChange={(e) => {
                        const selectedDate = e.target.value ? new Date(e.target.value) : null;
                        setLocalEndDate(selectedDate);
                        dispatch(setEndDateFilter(e.target.value))
                        dispatch(getActivities({}));
                    }}
                    size="small"
                />
                <Button variant="outlined" onClick={() => {

                    setLocalSymbolFilter("");
                    setLocalStatusFilter("");
                    setLocalAccountFilter("");
                    setLocalStartDate(null);
                    setLocalEndDate(null);

                    dispatch(setSymbolFilter(""));
                    dispatch(setStatusFilter(""));
                    dispatch(setAccountFilter(""));
                    dispatch(setStartDateFilter(""));
                    dispatch(setEndDateFilter(""));

                    dispatch(getActivities({}));
                }}>
                    Clear Filters
                </Button>
            </Box>

            <TableContainer component={Paper} style={{ marginTop: "10px" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Symbol</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Price</TableCell>
                            <TableCell>TIF</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Account</TableCell>
                            <TableCell>Legs</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities?.map((activity, index) => (
                            <TableRow key={activity.id}>
                                <TableCell>
                                    <Typography style={{ fontWeight: "bold" }}>
                                        {activity.symbol}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {getStatusIcon(activity.status)}
                                        <Typography>{getStatusString(activity.status)}</Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography component="span" style={{ color: "black" }}>
                                        {Number(activity.order?.price).toFixed(2)}
                                    </Typography>
                                    <Typography component="span" style={{ color: activity.order?.["price-effect"] === "Credit" ? "#4caf50" : "#f44336", marginLeft: "4px" }}>
                                        {activity.order?.["price-effect"] === "Credit" ? "cr" : "db"}
                                    </Typography>
                                </TableCell>
                                <TableCell>{activity.order?.["time-in-force"]}</TableCell>
                                <TableCell>{getDateString(activity)}</TableCell>
                                <TableCell>{activity.description}</TableCell>
                                <TableCell>{getAccountName(activity.accountId)}</TableCell>
                                <TableCell>
                                    {activity.legs.map((leg, legIndex) => (
                                        <Box
                                            key={legIndex}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{
                                                backgroundColor: leg.type === 'bto' ? '#e0f7fa' : '#ffebee',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                marginBottom: '3px',
                                                fontFamily: 'monospace',
                                                fontWeight: 'bold',
                                                width: 'fit-content',
                                                minWidth: '150px',
                                                justifyContent: 'space-evenly',
                                                gap: '8px'
                                            }}
                                        >
                                            <Typography>{leg.leg?.qty}</Typography>
                                            <Typography>{new Date(leg.leg?.option?.expDate ?? '').toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}</Typography>
                                            <Typography>{calculateDTE(leg.leg?.option.expDate)}d</Typography>
                                            <Typography>{leg.leg?.option.strike}</Typography>
                                            <Typography color={leg.type === 'bto' ? 'green' : 'red'}>{leg.type.toUpperCase()}</Typography>
                                        </Box>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {/* More (three-dot) menu */}
                                    <IconButton onClick={(event) => handleMenuOpen(event, index)}>
                                        <MoreVert />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dropdown Menu for Edit/Delete */}
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {/* <MenuItem onClick={() => {
                    if (selectedIndex !== null && activities[selectedIndex]) {
                        setSelectedActivity({ ...activities[selectedIndex] }); // Set the selected template
                        dispatch(toggleAddActivityDrawer(true));
                    }
                    handleMenuClose();
                }}>
                    <Edit fontSize="small" style={{ marginRight: 10 }} /> Edit
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteIndex(selectedIndex);
                    handleMenuClose();
                }} color="error">
                    <Delete fontSize="small" style={{ marginRight: 10 }} /> Delete
                </MenuItem> */}

                <MenuItem onClick={() => {
                    if (isEditable && selectedIndex !== null && activities[selectedIndex]) {
                        setSelectedActivity({ ...activities[selectedIndex] }); // Set the selected template
                        setReplaceOrder(false);
                        dispatch(cancelOrder({ id: activities[selectedIndex].id }))
                    }
                    handleMenuClose();
                }}
                    disabled={!isEditable}>
                    <Cancel fontSize="small" style={{ marginRight: 10 }} /> Cancel Order
                </MenuItem>
                <MenuItem onClick={() => {
                    if (isEditable && selectedIndex !== null && activities[selectedIndex]) {
                        setSelectedActivity({ ...activities[selectedIndex] }); // Set the selected template
                        setReplaceOrder(true);
                        dispatch(toggleAddActivityDrawer(true));
                    }
                    handleMenuClose();
                }}
                    disabled={!isEditable}>
                    <Repeat fontSize="small" style={{ marginRight: 10 }} /> Replace Order
                </MenuItem>
                <MenuItem onClick={() => {
                    if (selectedIndex !== null && activities[selectedIndex]) {
                        setSelectedActivity({ ...activities[selectedIndex] }); // Set the selected template
                        setReplaceOrder(false);
                        dispatch(toggleAddActivityDrawer(true));
                    }
                    handleMenuClose();
                }}>
                    <ContentCopy fontSize="small" style={{ marginRight: 10 }} /> Similar Order
                </MenuItem>
            </Menu>

            {/* Single Confirmation Dialog */}
            <Dialog open={deleteIndex !== null} onClose={() => setDeleteIndex(null)}>
                <DialogTitle>Delete Activity?</DialogTitle>
                <DialogContent>Are you sure you want to delete this activity?</DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteIndex(null)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            <AddActivity activity={selectedActivity} setActivity={setModifiedActivity} bReplaceOrder={replaceOrder} />
            <ReviewOrder activity={modifiedActivity} setActivity={setSelectedActivity} bReplaceOrder={replaceOrder} />
        </div>
    );
};

export default ActivityList;
